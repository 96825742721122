@import "support";

/*SignIn Signup*/
// .si_su_Layout {
//   overflow: hidden;
//   z-index: 2500;
//   position: fixed;
//   right: -2000px;
//   top: 0;
//   width: 550px;
//   height: 100%;
//   padding: 0px 50px;
//   background: $color-white;
//   background-image: $bgTexure;

//   .si_su_Close {
//     position: absolute;
//     z-index: 1000;
//     top: 10px;
//     right: 0px;
//     cursor: pointer;
//     font-family: $font-arial;
//     font-size: $fontsize-32;
//     color: $color-blue-darker;
//     width: 40px;
//     height: 40px;
//   }

//   .si_su_Close:hover {
//     color: $color-red;
//   }

//   .si_su_Close:before {
//     content: "x";
//   }

//   .si_su_ContentArea {
//     display: block;
//     overflow-y: hidden;

//     ::-webkit-input-placeholder {
//       color: $color-blacklight;
//     }

//     :-moz-placeholder {
//       color: $color-blacklight;
//     }

//     ::-moz-placeholder {
//       color: $color-blacklight;
//     }

//     :-ms-input-placeholder {
//       color: $color-blacklight;
//     }

//     input[type="text"],
//     input[type="password"] {
//       display: block;
//       font: $fontsize-18 $font-times;
//       color: $color-black;
//       width: 100%;
//       padding: 12px 15px;
//       border: 1px solid $color-black;
//       border-radius: 5px;
//       background: $color-white;
//       outline: 0;
//     }

//     .si_su_Head {
//       display: block;
//       font: $fontsize-28 $font-agaramondB;
//       color: $color-red;
//       margin: 20px 0px 5px 0px;
//     }

//     .si_su_sub {
//       display: block;
//       font-family: $font-times;
//       font-size: $fontsize-16;
//       color: $color-black;
//       margin-bottom: 35px;
//     }

//     .si_su_sub a {
//       text-decoration: none;
//       font-weight: bold;
//       color: $color-black;
//       border-bottom: 1px dashed $color-black;
//     }

//     .si_su_sub a:hover {
//       cursor: pointer;
//       color: $color-black;
//     }

//     .si_su_method {
//       display: block;
//       padding: 0 0 5px 0;
//       margin-bottom: 20px;
//       border-bottom: 2px solid $color-black;

//       .method_head {
//         display: block;
//         font: $fontsize-17 $font-agaramondB;
//         color: $color-black;
//         margin-bottom: 10px;
//       }

//       .twitterLogo,
//       .facebookLogo,
//       .googleLogo {
//         display: inline-block;
//         vertical-align: top;
//         height: 40px;
//         width: 130px;
//         margin-right: 10px;
//         border-radius: 8px;
//       }

//       .twitterLogo {
//         // background: $twitter_SVG;
//         background-position: center center;
//       }

//       .facebookLogo {
//         // background: $facebook_SVG;
//         background-position: center center;
//       }

//       .googleLogo {
//         // background: $google_SVG;
//         background-position: center center;
//       }

//       @media ($smaller-than: $breakpoint-699) {

//         .twitterLogo,
//         .facebookLogo,
//         .googleLogo {
//           width: calc(100% / 3 - 6px);
//           width: -webkit-calc(100%/3 - 6px);
//           margin-right: 5px;
//           border-radius: 0px;
//         }

//         .googleLogo {
//           margin-right: 0px;
//         }
//       }
//     }

//     .si_su_emailmethod {
//       display: block;
//       font-family: $font-agaramondB;
//       font-size: $fontsize-24;
//       color: $color-red;
//       margin-bottom: 25px;
//     }

//     .si_su_20margin_bottom {
//       display: block;
//       margin-bottom: 20px;
//     }

//     .si_su_Q {
//       display: block;
//       font: $fontsize-18 $font-agaramondB;
//       color: $color-black;
//       margin-bottom: 3px;

//       .si_su_note {
//         float: right;
//         font: $fontsize-13 $font-times;
//         color: $color-black;
//         padding-top: 2px;
//       }
//     }

//     .si_su_OTP_note {
//       display: block;
//       font: $fontsize-14 $font-times;
//       color: $color-black;
//       padding-top: 30px;
//     }

//     @media ($smaller-than: $breakpoint-699) {
//       .si_su_Head {
//         width: 90%;
//         font-size: $fontsize-22;
//       }

//       .si_su_sub {
//         margin-bottom: 20px;
//       }

//       .si_su_20margin_bottom {
//         margin-bottom: 15px;
//       }

//       .si_su_OTP_note {
//         font-size: $fontsize-13;
//         padding-top: 25px;
//       }

//       input[type="text"] {
//         font-size: $fontsize-16;
//         padding: 8px 10px;
//       }
//     }

//     .si_su_I_col {
//       display: block;
//     }

//     ul.si_su_II_col {
//       display: block;
//       margin: 0px;
//       padding: 0px;

//       li.si_su_II_col {
//         display: inline-block;
//         vertical-align: top;
//         width: calc(100% / 2 - 7.5px);
//         width: -webkit-calc(100%/2 - 7.5px);
//         margin: 0px 15px 25px 0px;
//       }

//       li.si_su_II_col:nth-child(even) {
//         margin-right: 0px;
//       }

//       li.si_su_II_col:last-child {
//         margin-bottom: 10px;
//       }

//       @media ($smaller-than: $breakpoint-699) {
//         li.si_su_II_col {
//           width: calc(100% / 2 - 8px);
//           width: -webkit-calc(100%/2 - 8px);
//           margin-right: 10px;
//         }
//       }
//     }

//     ul.si_su_II_col_by150 {
//       display: block;
//       margin: 0px;
//       padding: 0px;

//       li.si_su_II_col_by150 {
//         display: inline-block;
//         vertical-align: top;
//         width: calc(100% - 165px);
//         width: -webkit-calc(100% - 165px);
//         margin-right: 10px;
//       }

//       li.si_su_II_col_by150:last-child {
//         width: 150px;
//         margin-right: 0px;
//       }

//       @media ($smaller-than: $breakpoint-699) {
//         li.si_su_II_col_by150 {
//           display: block;
//           width: 100%;
//           margin: 0px;
//         }

//         li.si_su_II_col_by150:last-child {
//           width: 120px;
//         }
//       }
//     }

//     ul.si_su_II_col_40by60 {
//       display: block;
//       margin: 0px;
//       padding: 0px;

//       li.si_su_II_col_40by60 {
//         display: inline-block;
//         vertical-align: top;
//         width: 40%;
//         margin-right: 10px;
//       }

//       li.si_su_II_col_40by60:last-child {
//         width: calc(100% - 42.5%);
//         width: -webkit-calc(100% - 42.5%);
//         margin-right: 0px;
//       }

//       @media ($smaller-than: $breakpoint-699) {

//         li.si_su_II_col_40by60,
//         li.si_su_II_col_40by60:last-child {
//           display: block;
//           width: 100%;
//           margin: 0px;
//         }

//         li.si_su_II_col_40by60:last-child {
//           margin-top: -15px;
//         }
//       }
//     }

//     ul.si_su_IV_col {
//       display: block;
//       margin: 0px;
//       padding: 0px;

//       li.si_su_IV_col {
//         display: inline-block;
//         vertical-align: top;
//         width: calc(100% / 4 - 11px);
//         width: -webkit-calc(100%/4 - 11px);
//         margin-right: 10px;
//         font: $fontsize-17 $font-agaramondB;
//         color: $color-red;
//       }

//       li.si_su_IV_col:last-child {
//         margin-right: 0px;
//       }

//       li.si_su_IV_col.success {
//         color: $color-red;
//       }

//       @media ($smaller-than: $breakpoint-699) {
//         li.si_su_IV_col {
//           display: block;
//           width: 100%;
//           margin: 0 0 15px 0;
//         }
//       }
//     }

//     input[type="checkbox"] {
//       display: none;
//       cursor: pointer;
//     }

//     input[type="checkbox"]:not([disabled])+label {
//       cursor: pointer;
//     }

//     input[type="checkbox"]+label:before {
//       content: "";
//       display: inline-block;
//       width: 18px;
//       height: 18px;
//       vertical-align: middle;
//       margin: -7px 5px 0px 0;
//       border: 1px solid $color-black;
//       border-radius: 3px;
//       background: $color-white;
//     }

//     input[type="checkbox"]:checked+label:before {
//       content: "\2714";
//       color: $color-black;
//       font: $fontsize-14 $font-arial;
//       text-align: center;
//       line-height: 18px;
//     }

//     .button_verify {
//       display: block;
//       width: 100%;
//       padding: 12px 0px;
//       border: 0px solid $color-black;
//       border-radius: 5px;
//       font-family: $font-agaramondB;
//       font-size: $fontsize-18;
//       color: $color-white;
//       outline: none;
//       // background: $color-blue-navi;
//       background: $color-red;
//       text-align: center;
//     }

//     .button_verify:hover {
//       background: $color-red;
//       cursor: pointer;
//     }

//     .button_verify[disabled],
//     .button_verify[disabled]:hover,
//     .button_verify.disabled,
//     .button_verify.disabled:hover {
//       background: $color-black;
//       color: $color-black;
//       cursor: default;
//       text-shadow: none;
//       position: static;
//       -moz-box-shadow: none;
//       -webkit-box-shadow: none;
//       box-shadow: none;
//       border: 0px dotted $color-red;
//     }

//     @media ($smaller-than: $breakpoint-699) {
//       .button_verify {
//         margin-top: 10px;
//         padding: 10px 0px;
//         font-size: $fontsize-17;
//       }
//     }
//   }

//   .button_si_su_label {
//     display: block;
//     margin-bottom: 15px;

//     .button_si_su {
//       display: inline-block;
//       vertical-align: middle;
//       padding: 10px 25px;
//       margin-right: 5px;
//       border: 0px solid $color-black;
//       border-radius: 5px;
//       font: $fontsize-22 $font-agaramondB;
//       color: $color-white;
//       outline: none;
//       background: $color-black;
//       text-align: center;
//     }

//     .button_si_su:hover {
//       background: $color-black;
//       cursor: pointer;
//     }

//     .button_si_su[disabled],
//     .button_si_su[disabled]:hover,
//     .button_si_su.disabled,
//     .button_si_su.disabled:hover {
//       background: $color-black;
//       color: $color-black;
//       cursor: default;
//       text-shadow: none;
//       position: static;
//       -moz-box-shadow: none;
//       -webkit-box-shadow: none;
//       box-shadow: none;
//       border: 0px dotted $color-red;
//     }

//     @media ($smaller-than: $breakpoint-699) {
//       .button_si_su {
//         padding: 8px 20px;
//         font-size: $fontsize-20;
//       }
//     }
//   }

//   .si_su_terms {
//     display: block;
//     font: $fontsize-16 $font-times;
//     color: $color-black;
//   }

//   .si_su_terms a {
//     text-decoration: none;
//     font-weight: bold;
//     color: $color-black;
//     border-bottom: 1px dashed $color-black;
//   }

//   .si_su_terms a:hover {
//     cursor: pointer;
//     color: $color-black;
//   }

//   @media ($smaller-than: $breakpoint-699) {
//     .si_su_terms {
//       font-size: $fontsize-14;
//     }
//   }
// }

//Rate Quote
.rateQuote_pop {
  overflow: hidden;
  z-index: 2500;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  background: $color-white;
  width: 550px;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.07);

  .rateQuote_ContentArea {
    display: block;
    padding: 15px 30px 20px 30px;

    .rateQuote_Head {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-26;
      color: $color-red;
      text-align: center;
      margin: 0px -30px 0px -30px;
      padding-bottom: 12px;
      border-bottom: 2px solid $color-blacklight;
    }

    ::-webkit-input-placeholder {
      color: $color-blacklight;
    }

    :-moz-placeholder {
      color: $color-blacklight;
    }

    ::-moz-placeholder {
      color: $color-blacklight;
    }

    :-ms-input-placeholder {
      color: $color-blacklight;
    }

    select:required:invalid {
      color: $color-blacklight;
    }

    option {
      color: $color-black;
    }

    select::-ms-expand {
      display: none;
    }

    select {
      width: 100%;
      height: 37px;
      padding: 0px 10px;
      font-family: $font-agaramond;
      font-size: $fontsize-17;
      color: $color-black;
      border: 1px solid $color-blacklight;
      border-radius: 5px;
      outline: 0;
      // background: $selectarrow_blackIcon_SVG $color-offwhitelight;
      background-position: right 4% center;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }

    select:hover {
      cursor: pointer;
      // background: $selectarrow_redIcon_SVG;
      background-position: right 4% center;
      background-color: $color-white;
    }

    input[type="text"] {
      width: 100%;
      height: 37px;
      padding: 0px 10px;
      font-family: $font-agaramondB;
      font-size: $fontsize-18;
      color: $color-red;
      border: 1px solid $color-blacklight;
      border-radius: 5px;
      background-image: $bgTexure;
      outline: 0;
    }

    ul.rateQuoteIIcol {
      display: block;
      margin: 0px;
      padding: 0px;

      li.rateQuoteIIcol {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2 - 8px);
        width: -webkit-calc(100%/2 - 8px);
        margin-right: 15px;
      }

      li.rateQuoteIIcol:nth-child(even) {
        margin-right: 0px;
      }
    }

    .rq_formHead {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-16;
      color: $color-black;
      margin: 20px 0 2px 0;
    }

    .rq_button_label {
      display: block;
      margin: 15px 0 0 0;
      text-align: center;

      .rateQuoteBut {
        padding: 0px 18px;
        margin: 0px 4px;
        height: 42px;
        border: none;
        border-radius: 10px;
        font-family: $font-agaramondB;
        font-size: $fontsize-20;
        color: $color-white;
        background: $color-red;
        background-image: $bgTexure;
        outline: none;
      }

      .rateQuoteBut:hover {
        background: $color-red;
        cursor: pointer;
      }

      .ziplrateQuoteButookBut[disabled],
      .rateQuoteBut[disabled]:hover,
      .rateQuoteBut.disabled,
      .rateQuoteBut.disabled:hover {
        background: $color-blacklight;
        color: $color-blacklight;
        cursor: default;
        text-shadow: none;
        position: static;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 0px dotted $color-blacklight;
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .rateQuote_pop {
    width: 90%;
  }
}

/*Pop Structure Common Style*/
.mortgageApplied_pop,
.loanAmount_pop,
.interestRate_pop,
.loanTerm_pop,
.amortization_pop,
.loanPurpose_pop,
.occupancy_pop,
.coApplicant_pop,
.coBorrower_pop,
.loanType_pop,
.demographicInfo_pop,
.saveApplication_pop,
.authorize_pop,
.selectApplication_pop,
.changePassword_pop,
.profileComplete_pop,
.thirtyFixed_pop {
  min-height: 100px;
  position: absolute;
  background: $color-white;
  z-index: 2500;
  border-radius: 8px;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);

  ::-webkit-input-placeholder {
    color: $color-lightblack;
  }

  :-moz-placeholder {
    color: $color-lightblack;
  }

  ::-moz-placeholder {
    color: $color-lightblack;
  }

  :-ms-input-placeholder {
    color: $color-lightblack;
  }

  a {
    text-decoration: none;
    color: $color-blue;
    border-bottom: 1px dotted $color-blue;
  }

  input[type="text"],
  input[type="password"],
  textarea {
    height: 26px;
    width: 100%;
    padding: 0px 8px;
    border: 1px solid $color-red;
    border-radius: 3px;
    background: $color-red;
    font-family: $font-arial;
    font-size: $fontsize-12;
    color: $color-red;
    outline: 0;
  }

  textarea {
    height: 50px;
    padding-top: 5px;
  }

  select:required:invalid {
    color: $color-lightblack;
  }

  option {
    color: $color-red;
  }

  select::-ms-expand {
    display: none;
  }

  select {
    width: 100%;
    height: 26px;
    font-family: $font-arial;
    font-size: $fontsize-12;
    color: $color-red;
    border: 1px solid $color-red;
    border-radius: 3px;
    outline: 0;
    padding: 0 5px;
    background: $selectarrow_blackIcon_SVG;
    background-position: right center;
    background-color: $color-red;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  select:hover {
    cursor: pointer;
    background: $selectarrow_blueIcon_SVG;
    background-position: right center;
    background-color: $color-white;
  }

  input[type="checkbox"] {
    display: none;
    cursor: pointer;
  }

  input[type="checkbox"]:not([disabled])+label {
    cursor: pointer;
  }

  input[type="checkbox"]+label:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin: 0 5px 3px 0;
    border: 1px solid $color-blue;
    border-radius: 3px;
    background: $color-white;
  }

  input[type="checkbox"]:checked+label:before {
    content: "\2714";
    font-family: $font-arial;
    font-size: $fontsize-16;
    color: $color-blue;
    text-align: center;
    line-height: 17px;
  }

  input[type="radio"] {
    display: none;
    cursor: pointer;
  }

  input[type="radio"]:not([disabled])+label {
    cursor: pointer;
  }

  input[type="radio"]+label:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    border-radius: 50%;
    margin: 0 5px 3px 0;
    border: 1px solid $color-blue;
    background: $color-white;
  }

  input[type="radio"]:checked+label:before {
    content: "\2022";
    font-family: $font-arial;
    font-size: $fontsize-32;
    color: $color-blue;
    text-align: center;
    line-height: 14px;
  }

  .textBox_whiteBg input[type="text"],
  .textBox_whiteBg input[type="password"] {
    height: auto;
    padding: 5px 8px;
    border-color: $color-lightblack;
    background: $color-white;
    color: $color-black;
    margin-bottom: 15px;
    font-size: $fontsize-18;
    text-align: center;
    background: $bgTexure;
  }

  ul.pop_vBlk_2col {
    display: block;
    margin: 0px;

    li.pop_vBlk_2col {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2 - 6px);
      width: -webkit-calc(100%/2 - 6px);
      margin: 0px 7px 8px 0px;
    }

    li.pop_vBlk_2col:nth-child(even) {
      margin-right: 0px;
    }
  }

  ul.pop_vBlk_3col {
    display: block;
    margin: 0px;

    li.pop_vBlk_3col {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 3 - 8px);
      width: -webkit-calc(100%/3 - 8px);
      margin: 0px 7px 0px 0px;
    }

    li.pop_vBlk_3col:last-child {
      margin-right: 0px;
    }
  }

  .pop_head,
  .pop_head_bgRed {
    display: block;
    font-family: $font-agaramondB;
    font-size: $fontsize-22;
    color: $color-white;
    padding: 13px 0;
    background: $color-blue-navi-form;
    background-image: $bgTexure;
    text-align: center;
    border-radius: 8px 8px 0 0;
  }

  .pop_head_bgRed {
    background: $color-red;
    background-image: $bgTexure;
  }

  .pop_contentArea {
    display: block;
    padding: 15px 20px 10px 20px;
    max-height: 250px;
    overflow-y: auto;
  }

  .pop_contentHead,
  .pop_content {
    display: block;
    font-family: $font-agaramondB;
    font-size: $fontsize-16;
    color: $color-blackbrown;
  }

  .pop_content {
    font-family: $font-agaramond;
    font-size: $fontsize-15;
    color: $color-blackbrown-light;
  }

  .pop_formFieldHead,
  .pop_formFieldHead_center {
    display: block;
    font-family: $font-agaramondB;
    font-size: $fontsize-16;
    color: $color-black;
    margin-bottom: 3px;
  }

  .pop_formFieldHead_center {
    text-align: center;
  }

  .popConsent {
    display: block;
    text-align: center;
    padding: 15px 10px 10px 10px;
    border-top: 1px solid $color-blue-lighter;
    font-family: $font-agaramondB;
    font-size: $fontsize-14;
    color: $color-blackbrown-light;
    background-image: $bgTexure;

    .popAgree {
      display: block;
      padding: 5px 0 0 0;
      text-align: center;
      width: 100px;
      margin: 10px auto 0 auto;
      font-size: $fontsize-18;
      color: $color-blackbrown;
    }
  }

  @media ($smaller-than: $breakpoint-599) {
    .pop_head {
      font-size: $fontsize-20;
      padding: 10px 0;
    }

    .pop_contentArea {
      padding: 10px;
    }

    .pop_contentHead {
      font-size: $fontsize-15;
      text-align: center;
    }

    .pop_content {
      font-size: $fontsize-14;
      text-align: center;
    }

    .popConsent {
      font-size: $fontsize-12;
    }
  }

  ul.popSelectAppBlk {
    display: block;

    li.popSelectAppBlk {
      display: inline-block;
      vertical-align: top;
      width: 25px;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    li.popSelectAppBlk:nth-child(even) {
      width: calc(100% - 30px);
      width: -webkit-calc(100% - 30px);
      border-bottom: 1px dashed $color-blue-lighter;
    }

    li.popSelectAppBlk:last-child,
    li.popSelectAppBlk:nth-last-child(2) {
      border-bottom: none;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }

    ul.popSelectContentBlk {
      display: block;

      li.popSelectContentBlk {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 3px;
      }

      li.popSelectContentBlk:nth-child(odd) {
        width: 120px;
        font-family: $font-agaramondB;
        font-size: $fontsize-15;
        color: $color-blackbrown;
      }

      li.popSelectContentBlk:nth-child(even) {
        width: calc(100% - 125px);
        width: -webkit-calc(100% - 125px);
        font-family: $font-agaramond;
        font-size: $fontsize-15;
        color: $color-blackbrown-light;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      li.popSelectContentBlk:last-child,
      li.popSelectContentBlk:nth-last-child(2) {
        margin-bottom: 0px;
      }
    }
  }

  .pop_buttonLabel,
  .pop_buttonLabel_borderTop_Red {
    display: block;
    text-align: center;
    padding: 8px 0;
    margin-bottom: 5px;
    border-top: 1px solid $color-blue-lighter;

    .pop_button,
    .pop_button_bgRed {
      display: inline-block;
      vertical-align: top;
      background: $color-blue-navi-form;
      font-family: $font-agaramondB;
      font-size: $fontsize-16;
      color: $color-white;
      border-radius: 5px;
      border: none;
      padding: 0 15px;
      height: 32px;
      line-height: 32px;
      outline: none;
      margin: 0px 3px;
    }

    .pop_button_bgRed {
      background: $color-red;
      background-image: $bgTexure;
    }

    .pop_button:hover {
      cursor: pointer;
      background: $color-blue;
    }

    .pop_button_bgRed:hover {
      cursor: pointer;
      background: $color-red-dark;
    }

    .pop_button[disabled],
    .pop_button[disabled]:hover,
    .pop_button.disabled,
    .pop_button.disabled:hover,
    .pop_button_bgRed[disabled],
    .pop_button_bgRed[disabled]:hover,
    .pop_button_bgRed.disabled,
    .pop_button_bgRed.disabled:hover {
      background: $color-lightblack;
      color: $color-offblack;
      cursor: default;
      text-shadow: none;
      position: static;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 0px dotted $color-disable;
    }
  }

  .pop_buttonLabel_borderTop_Red {
    border-top: 1px solid $color-red-lighter;
  }

  .pop_buttonLabel1 {
    display: block;
    text-align: center;
    padding: 8px 0;
    margin-bottom: 5px;

    .pop_button1 {
      display: inline-block;
      vertical-align: top;
      font-family: $font-agaramondB;
      font-size: $fontsize-18;
      color: $color-blue-logo;
      background-image: $bgTexure;
      border-radius: 5px;
      border: none;
      padding: 0 20px;
      height: 45px;
      line-height: 45px;
      outline: none;
      margin: 0 5px 0px 0;
    }

    .pop_button1:hover {
      cursor: pointer;
      color: $color-blue-navi;
    }
  }
}

.mortgageApplied_pop,
.demographicInfo_pop,
.amortization_pop,
.loanType_pop,
.loanPurpose_pop,
.selectApplication_pop {
  width: 500px;
}

.authorize_pop {
  width: 650px;
}

/*.getStarted_pop {
  width: 525px;	
}*/
.saveApplication_pop,
.loanAmount_pop,
.interestRate_pop,
.loanTerm_pop,
.occupancy_pop,
.coApplicant_pop,
.coBorrower_pop {
  width: 400px;
}

.changePassword_pop,
.profileComplete_pop,
.thirtyFixed_pop {
  width: 350px;
}

@media ($smaller-than: $breakpoint-799) {
  .authorize_pop {
    width: 500px;
  }
}

@media ($smaller-than: $breakpoint-499) {

  .mortgageApplied_pop,
  .demographicInfo_pop,
  .amortization_pop,
  .loanType_pop,
  .loanPurpose_pop,
  .selectApplication_pop {
    top: 40%;
    width: 250px;
  }
}