@import "support";

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* Use the variables */
body {
    font-family: $font-agaramond;
    font-size: $fontsize-16;
    width: 100%;
    height: 100%;
    color: $color-black;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
}

body>#root {
    width: 100%;
    height: 100%;
}

#homeBgImg {
    margin: 0px;
    width: 100%;
    height: 100%;
    background-image: url("../img/house.jpg");
    background-size: cover;
    background-position-y: 50px;
    background-repeat: no-repeat;

    .homeTxt_blk {
        position: absolute;
        top: 27%;
        width: 47%;
        z-index: 15;
        background: $transparent_blue_80;
        padding: 40px 50px;
        border-radius: 10px;

        .mainTxt {
            display: block;
            font-family: $font-agaramondB;
            font-size: $fontsize-45;
            color: $color-white;
            text-transform: uppercase;
            letter-spacing: 3px;
            margin-bottom: 15px;
        }

        .supportTxt {
            display: block;
            font-family: $font-agaramond;
            font-size: $fontsize-17;
            color: $color-lighterwhite;
            margin-bottom: 20px;
            line-height: $fontsize-22;
        }

        .more_button {
            display: inline-block;
            padding: 15px 30px;
            font-family: $font-agaramondB;
            font-size: $fontsize-16;
            color: $color-white;
            text-transform: uppercase;
            border: 0px;
            background: $color-red;
            outline: none;
            border-radius: 5px;
            transition-property: 'background';
            transition-duration: 0.5s;
            -webkit-transition: 0.5s;
            transition: 0.5s;
        }

        .more_button:hover {
            cursor: pointer;
            background: $color-red-light;
        }
    }

    @media screen and (max-width:$breakpoint-1200) {
        .homeTxt_blk {
            top: 18%;
            width: 85%;
        }
    }

    @media screen and (max-width:$breakpoint-599) {
        .homeTxt_blk {
            top: 13%;
            width: 96%;

            .mainTxt {
                font-size: $fontsize-34;
            }
        }
    }

    @media screen and (max-width:$breakpoint-499) {
        .homeTxt_blk {
            padding: 30px 30px;

            .mainTxt {
                font-size: $fontsize-24;
            }

            .supportTxt {
                font-size: $fontsize-15;
            }

            .more_button {
                padding: 10px 20px;
                font-size: $fontsize-15;
            }
        }
    }
}

#home_bottom_blk {
    display: block;
    visibility: visible;
    width: 100%;
    height: 100px;
    left: 0px;
    top: 0px;
    position: fixed;
    background: -webkit-linear-gradient(to bottom,
            rgba(0, 0, 0, 0),
            rgba(24, 37, 62, 1) 85%);
    background: -moz-linear-gradient(to bottom,
            rgba(0, 0, 0, 0),
            rgba(24, 37, 62, 1) 85%);
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0),
            rgba(24, 37, 62, 1) 85%);

    ul.home_III_col {
        display: block;
        margin: 0px;
        padding: 0px;

        li.home_III_col {
            display: inline-block;
            vertical-align: top;
            width: calc(100%/2 - 290px);
            width: -webkit-calc(100%/2 - 290px);
            margin-right: 40px;
            border-top: 6px solid $color-white;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            padding: 18px 0px 0px 15px;
        }

        li.home_III_col:last-child {
            width: 500px;
            margin-right: 0px;
            border: none;
            background: $color-red;
            font-family: $font-agaramondB;
            font-size: $fontsize-34;
            color: $color-white;
            text-align: center;
            line-height: 100px;
            text-transform: capitalize;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 0px;
            box-shadow: 0px -2px 5px rgba(255, 255, 255, .25);
        }

        .bottomIcon1,
        .bottomIcon2 {
            display: inline-block;
            vertical-align: top;
            width: 95px;
            height: 75px;
        }

        .bottomIcon1 {
            background: $bestRateIcon_SVG;
            background-size: 75px;
            background-position: left top 4px;
        }

        .bottomIcon2 {
            width: 85px;
            background: $successRateIcon_SVG;
            background-size: 65px;
            background-position: left top;
        }

        .bottomText {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 95px);
            width: -webkit-calc(100% - 95px);
            font-family: $font-agaramondB;
            font-size: $fontsize-26;
            color: $color-white;
            line-height: $fontsize-18;
        }

        @media screen and (max-width:$breakpoint-1023) {
            li.home_III_col {
                width: calc(100%/2 - 20px);
                width: -webkit-calc(100%/2 - 20px);
            }

            li.home_III_col:nth-child(2) {
                margin-right: 0px;
            }

            li.home_III_col:last-child {
                display: none;
            }
        }

        @media screen and (max-width:$breakpoint-599) {

            .bottomIcon1,
            .bottomIcon2 {
                display: none;
            }

            .bottomText {
                display: block;
                width: 100%;
                font-size: $fontsize-22;
                line-height: normal;
            }
        }
    }
}

/*Bigger than 250px */
@media screen and (min-width:$breakpoint-249) {
    #home_bottom_blk {
        top: auto;
        bottom: 0px;
        position: absolute;
    }
}

/*Smaller than height 500px */
@media screen and (max-height: $breakpoint-499) {
    #home_bottom_blk {
        display: none;
    }
}

/*ABOUT US*/
ul.aboutContainer {
    display: block;
    margin: 0px;
    background: $bgTexure $color-white-light;
    padding-bottom: 50px;

    li.aboutContainer {
        display: inline-block;
        vertical-align: top;
        width: -webkit-calc(100% - 500px);
        width: calc(100% - 500px);
        padding: 40px 50px 0px 0px;
    }

    li.aboutContainer:last-child {
        width: 500px;
        background: $color-white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 50px 50px 40px 50px;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, .25);
        text-align: center;
    }

    .abt_heroTxt {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-42;
        color: $color-blue-navi-bg;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .abt_pointHeadTxt {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-20;
        color: $color-red;
        margin-bottom: 5px;
    }

    .abt_pointTxt {
        display: block;
        font-family: $font-agaramond;
        font-size: $fontsize-17;
        color: $color-black;
        margin-bottom: 24px;
    }

    ul.abt_uspPoint {
        display: block;
        margin-top: 30px;

        li.abt_uspPoint {
            display: inline-block;
            vertical-align: top;
            text-transform: uppercase;
            width: calc(100%/2);
            width: -webkit-width (100% /2);
            font-family: $font-agaramondB;
            font-size: $fontsize-16;
            color: $color-black;
            margin-bottom: 10px;
        }

        li.abt_uspPoint::before {
            content: '\0000bb';
            margin-right: 10px;
            color: $color-black;
            font-size: $fontsize-30;
        }
    }

    .howMuchHeadText {
        display: none;
        width: 100%;
        border: none;
        background: $color-red;
        font-family: $font-agaramondB;
        font-size: $fontsize-34;
        color: $color-white;
        text-align: center;
        line-height: 100px;
        text-transform: capitalize;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 0px;
        box-shadow: 0px -2px 5px rgba(255, 255, 255, .25);
    }

    .setLoanAmt {
        display: block;
        background: url(../img/SetLoanAmt.png) $color-white;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 320px;
    }

    .apply_button {
        width: 80%;
        margin: 20px auto 0px auto;
        padding: 15px 0px;
        font-family: $font-agaramondB;
        font-size: $fontsize-22;
        color: $color-white;
        text-transform: capitalize;
        border: 0px;
        background: $color-red;
        outline: none;
        border-radius: 5px;
    }

    .apply_button:hover {
        cursor: pointer;
        background: $color-red-light;
    }

    @media screen and (max-width:$breakpoint-1023) {
        li.aboutContainer {
            display: block;
            width: 100%;
            padding-right: 10px;
        }

        li.aboutContainer:first-child {
            margin-left: 10px;
        }

        li.aboutContainer:last-child {
            width: 100%;
            margin-top: 50px;
        }

        .howMuchHeadText {
            display: block
        }
    }

    @media screen and (max-width:$breakpoint-599) {

        li.aboutContainer:last-child {
            padding: 0px 0px 30px 0px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        .abt_heroTxt {
            font-size: $fontsize-28;
        }

        .abt_pointTxt {
            font-size: $fontsize-15;
            margin-bottom: 18px;
        }

        ul.abt_uspPoint {
            li.abt_uspPoint {
                font-size: $fontsize-14;
            }
        }

        .howMuchHeadText {
            font-size: $fontsize-20;
            line-height: 60px;
        }

        .setLoanAmt {
            margin: 0px 20px;
            height: 270px;
        }

        .apply_button {
            width: auto;
            font-size: $fontsize-18;
            padding: 10px 20px;
        }
    }

}

/*USP*/
ul.uspContainer {
    display: block;
    margin: 0px;
    background: $bgTexure $color-blue-navi-bg;
    padding: 60px 0px 50px 0px;

    li.usp_IVcol {
        display: inline-block;
        vertical-align: top;
        width: -webkit-calc(100% / 4);
        width: calc(100% / 4);
        border-right: 1px solid $color-white-light;
    }

    li.usp_IVcol:last-child {
        border-right: 0px;
    }

    .uspPointHead {
        display: block;
        text-align: center;
        font-family: $font-agaramondB;
        font-size: $fontsize-48;
        color: $color-white;
        margin-top: -15px;
    }

    .uspPointAns {
        display: block;
        text-align: center;
        font-family: $font-agaramond;
        font-size: $fontsize-18;
        color: $color-white;
        margin-bottom: -10px;
    }

    @media screen and (max-width:$breakpoint-599) {
        li.usp_IVcol {
            width: -webkit-calc(100% / 2);
            width: calc(100% / 2);
        }

        li.usp_IVcol:nth-child(even) {
            border-right: 0px;
            margin-bottom: 60px;
        }

        li.usp_IVcol:last-child {
            margin-bottom: 0px;
        }

        .uspPointHead {
            font-size: $fontsize-30;
        }
    }
}

@media screen and (max-width:$breakpoint-599) {
    ul.uspContainer {
        padding: 40px 0px;
    }
}

/*ENEFITS*/
ul.benefitContainer {
    display: block;
    margin: 0px 0px 70px 0px;
    padding: 0px;
    background-image: linear-gradient(to right, $color-red 30%, $color-white 0%);

    li.benefits {
        display: inline-block;
        vertical-align: top;
    }

    li.benefits {
        width: 500px;
        background: $color-red;
    }

    li.benefits:last-child {
        width: calc(100% - 500px);
        width: -webkit-calc(100% - 500px);
        padding-left: 60px;
        background: $color-white;
    }

    .benefitImage {
        margin-top: 70px;
        width: 500px;
        height: 560px;
        background-image: url("../img/benefit1.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        border-right: 1px solid $color-red-lightest;
        border-bottom: 1px solid $color-red-lightest;
    }

    .benefit_headTxt {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-18;
        color: $color-red;
        margin: 70px 0px 5px 0px;
    }

    .benefit_headTxt::before {
        content: '||';
        background: $color-white;
        margin-right: 8px;
    }

    .benefit_heroTxt {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-38;
        color: $color-blue-navi-bg;
        margin-bottom: 15px;
        text-transform: capitalize;
    }

    .benefitTxt {
        display: block;
        font-family: $font-agaramond;
        font-size: $fontsize-17;
        color: $color-black;
        margin-bottom: 30px;
    }

    ul.benefitPoints {
        display: block;

        li.benefitPoints {
            display: inline-block;
            vertical-align: top;
        }

        li.benefitPoints:nth-child(1n + 0) {
            width: 80px;
        }

        li.benefitPoints:nth-child(2),
        li.benefitPoints:nth-child(5),
        li.benefitPoints:nth-child(8) {
            width: 20px;

            .rightCurve {
                position: relative;
                width: 30px;
                height: 55px;
                border-right: 7px solid $color-red-lightest;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                left: -20px;
                top: 10px;
            }
        }

        li.benefitPoints:nth-child(3n + 0) {
            width: calc(100% - 130px);
            width: -webkit-calc(100% - 130px);
            padding-left: 15px;
            margin-bottom: 30px;
        }

        li.benefitPoints:nth-last-child(1) {
            margin-bottom: 0px;
        }

        .benefitPointIcon {
            display: block;
            width: 75px;
            height: 75px;
            border-radius: 50px;
            border: 12px solid $color-red-lightest;

            .benIcon01,
            .benIcon02,
            .benIcon03 {
                position: absolute;
                width: 50px;
                aspect-ratio: 1/1;
            }

            .benIcon01 {
                background: $benefitIcon1_SVG;
            }

            .benIcon02 {
                background: $benefitIcon2_SVG;
            }

            .benIcon03 {
                background: $benefitIcon3_SVG;
            }

            .benIcon01,
            .benIcon02,
            .benIcon03 {
                background-size: 33px;
                background-position: center;
            }
        }

        .benefitPointHead {
            display: block;
            font-family: $font-agaramondB;
            font-size: $fontsize-18;
            color: $color-black;
            padding-top: 5px;
        }

        .benefitPointText {
            display: block;
            font-family: $font-agaramond;
            font-size: $fontsize-16;
            color: $color-blacklight;
            padding-top: 3px;
        }
    }

    @media screen and (max-width:$breakpoint-599) {
        li.benefits:first-child {
            display: none;
        }

        li.benefits:last-child {
            display: block;
            width: 100%;
            padding: 0px 0px 0px 10px;
        }

        .benefit_headTxt {
            margin-top: 50px;
        }

        .benefit_heroTxt {
            font-size: $fontsize-30;
        }

        .benefitTxt {
            font-size: $fontsize-15;
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width:$breakpoint-599) {
    ul.benefitContainer {
        background-image: none;
    }
}

/*SERVICES*/
.serviceContainer {
    display: block;
    margin: 0px;
    padding: 70px 0px;
    background: $bgTexure $color-white-light;

    .serv_blkHead,
    .serv_blkTxtDesc {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-18;
        color: $color-red;
        margin-bottom: 5px;
        text-align: center;
    }

    .serv_blkHead::before,
    .serv_blkHead::after {
        content: '||';
        background: $bgTexure $color-white-light;
        margin: 0px 8px;
    }

    .serv_blkTxtDesc {
        font-family: $font-agaramondB;
        font-size: $fontsize-38;
        color: $color-blue-navi-bg;
        margin-bottom: 10px;
        text-transform: capitalize;
    }

    .servTxt {
        display: block;
        font-family: $font-agaramond;
        font-size: $fontsize-17;
        color: $color-black;
        margin-bottom: 50px;
        text-align: center;
        width: 60%;
        margin: 0px auto;
    }

    @media screen and (max-width:$breakpoint-599) {
        .serv_blkTxtDesc {
            font-family: $font-agaramondB;
            font-size: $fontsize-30;
        }

        .servTxt {
            font-size: $fontsize-15;
            width: 100%;
            margin-bottom: 30px;
        }
    }

    ul.loPointsDescBlk {
        display: block;

        li.loPointsDescBlk {
            display: block;
            margin-bottom: 30px;
        }

        @media ($smaller-than: $breakpoint-999) {
            li.loPointsDescBlk {
                display: inline-block;
                vertical-align: top;
                margin: 0px;
            }

            li.loPointsDescBlk:first-child {
                width: 160px;
            }

            li.loPointsDescBlk:last-child {
                width: calc(100% - 180px);
                width: -webkit-calc(100% - 180px);
                margin-left: 20px;
            }
        }

        @media ($smaller-than: $breakpoint-599) {
            li.loPointsDescBlk:first-child {
                width: 100px;
            }

            li.loPointsDescBlk:last-child {
                width: calc(100% - 110px);
                width: -webkit-calc(100% - 110px);
                margin-left: 10px;
            }
        }
    }

    ul.loDescBlk {
        display: block;
        margin-top: 15px;

        li.loDescBlk {
            display: inline-block;
            vertical-align: top;
        }

        li.loDescBlk:first-child {
            width: 375px;
        }

        li.loDescBlk:last-child {
            width: calc(100% - 375px);
            width: -webkit-calc(100% - 375px);
            background: url(../img/lo_bg.jpg) no-repeat;
            background-size: cover;
            height: 600px;
        }

        @media ($smaller-than: $breakpoint-999) {

            li.loDescBlk,
            li.loDescBlk:first-child {
                display: block;
                width: 100%;
            }

            li.loDescBlk:last-child {
                display: none;
            }
        }

        .loContentBox {
            position: absolute;
            width: 600px;
            transform: translate(0%, 10%);
            background: $color-white;
            background-image: $bgTexure;
            box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.35);
            padding: 50px 60px;

            .loDescHead {
                display: block;
                font-family: $font-times;
                font-weight: bold;
                font-size: $fontsize-34;
                color: $color-blue-darker;
                margin-bottom: 35px;
            }

            @media ($smaller-than: $breakpoint-999) {
                .loDescHead {
                    font-size: $fontsize-28;
                }
            }

            @media ($smaller-than: $breakpoint-599) {
                .loDescHead {
                    font-size: $fontsize-20;
                    margin-bottom: 20px;
                }
            }

            ul.loDescPoints {
                display: block;

                li.loDescPoints {
                    display: block;
                    font-family: $font-times;
                    font-weight: bold;
                    font-size: $fontsize-24;
                    color: $color-blacklight;
                    margin-bottom: 30px;
                }

                li.loDescPoints:last-child {
                    margin-bottom: 50px;
                }

                li.loDescPoints::before {
                    content: "\25BA";
                    margin-right: 15px;
                    color: $color-red;
                }

                @media ($smaller-than: $breakpoint-999) {
                    li.loDescPoints {
                        font-size: $fontsize-18;
                        margin-bottom: 25px;
                    }
                }

                @media ($smaller-than: $breakpoint-599) {
                    li.loDescPoints {
                        font-size: $fontsize-15;
                        margin-bottom: 15px;
                    }

                    li.loDescPoints:last-child {
                        margin-bottom: 30px;
                    }

                    li.loDescPoints::before {
                        margin-right: 5px;
                        font-size: $fontsize-14;
                    }
                }
            }

            ul.loButtonLabel {
                display: block;

                li.loButtonLabel,
                li.loGetQuote {
                    display: inline-block;
                    vertical-align: top;
                    width: calc(100% / 2);
                    width: -webkit-calc(100% / 2);
                }

                li.loGetQuote {
                    text-align: right;
                    padding-top: 10px;
                    text-decoration: none;
                }

                li.loGetQuote .getQuotelink {
                    font-family: $font-agaramondB;
                    font-size: $fontsize-20;
                    color: $color-blacklight;
                }

                li.loGetQuote .getQuotelink:hover {
                    color: $color-blacklight;
                }

                li.loGetQuote .getQuotelink::after {
                    content: "\0000bb";
                    margin-left: 10px;
                    font-size: $fontsize-30;
                    color: $color-red;
                }

                .loButton {
                    padding: 20px 40px;
                    border: none;
                    border-radius: 2px;
                    font-family: $font-times;
                    font-weight: bold;
                    font-size: $fontsize-24;
                    color: $color-white;
                    text-transform: uppercase;
                    background: $color-red;
                    background-image: $bgTexure;
                    outline: none;
                    transition: background 0.5s;
                    -webkit-transition: background 0.5s;
                }

                .loButton:hover {
                    background: $color-red;
                    cursor: pointer;
                }

                @media ($smaller-than: $breakpoint-999) {
                    li.loGetQuote {
                        display: none;
                    }

                    .loButton {
                        padding: 10px 20px;
                        font-size: $fontsize-20;
                    }
                }

                @media ($smaller-than: $breakpoint-599) {
                    .loButton {
                        padding: 8px 15px;
                        font-size: $fontsize-16;
                    }
                }
            }
        }

        @media ($smaller-than: $breakpoint-999) {
            .loContentBox {
                transform: translate(0%, 0%);
                width: calc(100% - 200px);
                width: -webkit-calc(100% - 200px);
                padding: 25px;
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
                min-height: 360px;
            }
        }

        @media ($smaller-than: $breakpoint-599) {
            .loContentBox {
                width: calc(100% - 130px);
                width: -webkit-calc(100% - 130px);
                padding: 15px;
                min-height: 370px;
            }
        }
    }

    @media ($smaller-than: $breakpoint-999) {
        ul.loDescBlk {
            margin-top: -2px;
        }
    }

    ul.loPgStructure {
        display: block;
        margin-top: 35px;

        li.loPgStructure {
            display: block;
            margin-bottom: 30px;
        }

        li.loPgStructure:last-child {
            padding-top: 15px;
        }

        @media ($smaller-than: $breakpoint-1200) {
            li.loPgStructure {
                display: inline-block;
                vertical-align: top;
                margin: 0px;
            }

            li.loPgStructure:first-child {
                display: none;
            }

            li.loPgStructure:last-child {
                width: 100%;
            }
        }
    }

    ul.loIconNavi {
        display: block;
        margin-top: 20px;

        li.loIconNavi {
            display: inline;
        }

        li.loIconNavi {
            margin-right: 20px;
        }

        li.loIconNavi:last-child {
            margin-right: 0px;
        }

        li.loIconNavi a {
            display: inline-block;
            width: calc(100% / 6 - 17px);
            width: -webkit-calc(100% / 6 - 17px);
            font-family: $font-agaramondB;
            font-size: $fontsize-19;
            color: $color-blacklight;
            background: $color-white;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
            text-align: center;
            text-decoration: none;
            padding: 25px 40px;
            transition-property: color;
            transition-duration: 0.5s;
            -webkit-transition: 0s;
            transition: 0.5s;
            transition-delay: 0.1s;
        }

        li.loIconNavi a:hover {
            color: $color-red;
            cursor: pointer;
        }

        li.loIconNavi a.current {
            position: relative;
            background: $color-red;
            color: $color-white;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
        }

        li.loIconNavi a.current:after {
            content: " ";
            position: absolute;
            height: 0;
            width: 0;
            border: 15px solid transparent;
            border-left: 25px solid transparent;
            border-right: 25px solid transparent;
            border-top-color: $color-red;
            top: 165px;
            left: calc(50% - 25px);
            left: -webkit-calc(50% - 25px);
        }

        .lo_icon1,
        .lo_icon1_active,
        .lo_icon2,
        .lo_icon2_active,
        .lo_icon3,
        .lo_icon3_active,
        .lo_icon4,
        .lo_icon4_active,
        .lo_icon5,
        .lo_icon5_active,
        .lo_icon6,
        .lo_icon6_active {
            width: 50px;
            height: 65px;
            margin: 0px auto 7px auto;
        }

        .lo_icon1 {
            background: $serv_newhome_SVG;
        }

        .lo_icon1_active {
            background: $serv_newhome_white_SVG;
        }

        .lo_icon2 {
            background: $serv_refin_SVG;
        }

        .lo_icon2_active {
            background: $serv_refin_white_SVG;
        }

        .lo_icon3 {
            background: $serv_capital_SVG;
        }

        .lo_icon3_active {
            background: $serv_capital_white_SVG;
        }

        .lo_icon4 {
            background: $serv_renovation_SVG;
        }

        .lo_icon4_active {
            background: $serv_renovation_white_SVG;
        }

        .lo_icon5 {
            background: $serv_refinAdv_SVG;
        }

        .lo_icon5_active {
            background: $serv_refinAdv_white_SVG;
        }

        .lo_icon6 {
            background: $serv_preQualify_SVG;
        }

        .lo_icon6_active {
            background: $serv_preQualify_white_SVG;
        }

        .lo_icon1,
        .lo_icon1_active,
        .lo_icon2,
        .lo_icon2_active,
        .lo_icon3,
        .lo_icon3_active,
        .lo_icon4,
        .lo_icon4_active,
        .lo_icon5,
        .lo_icon5_active,
        .lo_icon6,
        .lo_icon6_active {
            background-size: 50px;
            background-position: center center;
        }

        @media ($smaller-than: $breakpoint-999) {
            li.loIconNavi a.current {
                box-shadow: none;
                background-image: none;
            }

            li.loIconNavi a.current:after {
                display: none;
            }

            li.loIconNavi {
                margin-bottom: -12px;
            }

            li.loIconNavi a {
                display: block;
                width: 100%;
                height: auto;
                font-size: $fontsize-16;
                text-align: left;
                border-top: 1px dashed $color-red;
                padding: 0px;
                padding: 5px 0px 5px 5px;
                line-height: 17px;
            }

            li.loIconNavi a.current:after {
                border: 15px solid transparent;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-left-color: $color-red;
                left: 195px;
                top: calc(50% - 8px);
                top: -webkit-calc(50% - 8px);
            }

            .lo_icon1,
            .lo_icon1_active,
            .lo_icon2,
            .lo_icon2_active,
            .lo_icon3,
            .lo_icon3_active,
            .lo_icon4,
            .lo_icon4_active,
            .lo_icon5,
            .lo_icon5_active,
            .lo_icon6,
            .lo_icon6_active {
                display: inline-block;
                vertical-align: middle;
                width: 45px;
                height: 35px;
                background-size: 25px;
                background-position: left 5px center;
            }
        }

        @media ($smaller-than: $breakpoint-599) {
            li.loIconNavi a {
                display: block;
                width: 100%;
                height: auto;
                font-size: $fontsize-15;
                text-align: left;
                border-top: 1px dashed $color-red;
                padding: 5px 0px 5px 5px;
                line-height: 17px;
            }

            li.loIconNavi a.current:after {
                border: 8px solid transparent;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left-color: $color-red;
                left: 135px;
                top: calc(50% - 5px);
                top: -webkit-calc(50% - 5px);
            }

            .lo_icon1,
            .lo_icon1_active,
            .lo_icon2,
            .lo_icon2_active,
            .lo_icon3,
            .lo_icon3_active,
            .lo_icon4,
            .lo_icon4_active,
            .lo_icon5,
            .lo_icon5_active,
            .lo_icon6,
            .lo_icon6_active {
                display: none;
            }
        }
    }

    @media ($smaller-than: $breakpoint-999) {
        ul.loIconNavi {
            margin-top: 0px;
        }
    }


    ul.loPointsBlk {
        display: block;

        li.loPointsBlk {
            display: inline-block;
            vertical-align: top;
            margin: 0px;
        }

        li.loPointsBlk:first-child {
            width: 350px;
            padding-top: 10px;
        }

        li.loPointsBlk:last-child {
            width: calc(100% - 370px);
            width: -webkit-calc(100% - 370px);
            margin-left: 20px;
        }

        @media ($smaller-than: $breakpoint-999) {
            li.loPointsBlk:first-child {
                width: 250px;
            }

            li.loPointsBlk:last-child {
                width: calc(100% - 260px);
                width: -webkit-calc(100% - 260px);
                margin-left: 10px;
            }
        }

        @media ($smaller-than: $breakpoint-599) {
            li.loPointsBlk:first-child {
                display: none;
            }

            li.loPointsBlk:last-child {
                width: 100%;
                margin-left: 0px;
            }
        }

        ul.loDescPoints {
            display: block;

            li.loDescPoints {
                display: block;
                font-family: $font-times;
                font-weight: bold;
                font-size: $fontsize-18;
                color: $color-black;
                margin-bottom: 25px;
            }

            li.loDescPoints::before {
                content: "\25BA";
                margin-right: 10px;
                color: $color-red;
            }

            .loButton {
                margin: 10px 0px 20px 0px;
                padding: 15px 20px;
                border: none;
                border-radius: 2px;
                font-family: $font-times;
                font-weight: bold;
                font-size: $fontsize-18;
                color: $color-white;
                text-transform: uppercase;
                background: $color-red;
                background-image: $bgTexure;
                outline: none;
                transition: background 0.5s;
                -webkit-transition: background 0.5s;
            }

            .loButton:hover {
                background: $color-red;
                cursor: pointer;
            }
        }
    }
}

@media ($smaller-than: $breakpoint-999) {
    .serviceContainer {
        padding: 50px 0px 20px 0px;
    }
}