@import "support";

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* Use the variables */
body {
    background: $color-white;
    font-family: $font-agaramond;
    font-size: $fontsize-16;
    width: 100%;
    height: 100%;
    color: $color-black;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
}

body>#root {
    height: 100%;
    width: 100%;
}

body>#root>#container {
    height: auto;
    min-height: 100%;
}

#container {
    color: $color-black;
    height: 100%;
    background: $color-white;
    // background-image: $bgTexure;

    #content {
        padding-top: 40px;
        padding-bottom: 60px;
    }

    #content_fullHeader {
        padding-top: 92px;
        padding-bottom: 100px;
    }

    @media ($smaller-than: $breakpoint-1200) {
        #content_fullHeader {
            padding-top: 42px;
        }
    }

    @media ($smaller-than: $breakpoint-999) {}
}

//Common Inner Page Style

#bc_option_nonqm {
    margin: 0px;
    width: 100%;
    height: 240px;
    background: $color-red;
    background-image: linear-gradient(to right, #8d95a8, #c2c6ce);
    // background-image: url("../img/bc_nonqm.jpg");
    // background-size: cover;
    // background-position-y: -30px;
    // background-repeat: no-repeat;
    border-bottom: 1px solid $color-lighterwhite;

    .bcTxt_blk {
        position: absolute;
        top: 25%;
        width: auto;
        z-index: 15;
        background: $transparent_black_10;
        padding: 7px 0px 7px 15px;

        .bcTxt {
            display: inline-block;
            vertical-align: top;
            font-family: $font-agaramondB;
            font-size: $fontsize-15;
            color: $color-white;
            margin-right: 7px;
        }

        .bcTxt::after {
            padding-left: 7px;
            content: "\00232a";
        }

        .bcTxt:last-child::after {
            content: "";
        }

        .bcTxt a {
            color: $color-white;
        }
    }
}

@media ($smaller-than: $breakpoint-1200) {
    #bc_option_nonqm {
        display: none;
    }
}

ul.inPageBreadcrumbBlk {
    display: block;
    margin-top: 50px;

    li.inPageBreadcrumbBlk {
        display: inline-block;
        vertical-align: top;
        width: calc(100%/2);
        -webkit-width: calc(100%/2);
    }

    li.inPageBreadcrumbBlk:last-child {
        text-align: right;
    }

    .pgBlkDesc {
        display: inline-block;
        font-family: $font-agaramondB;
        font-size: $fontsize-14;
        color: $color-blacklight;
        background: $color-white-light;
        text-transform: uppercase;
        padding-left: 12px;
        margin-bottom: 5px;
        height: 30px;
        line-height: 33px;

        .curveShape {
            float: right;
            width: 30px;
            border-right: 15px solid $color-white;
            border-bottom: 30px solid $color-white-light;
        }
    }

    .pgDescHeadTxt {
        display: block;
        font-family: $font-agaramondB;
        font-weight: bold;
        font-size: $fontsize-38;
        color: $color-blue-navi-bg;
        // margin-bottom: 30px;
        padding-left: 12px;
        text-transform: capitalize;
    }

    @media ($smaller-than: $breakpoint-799) {
        li.inPageBreadcrumbBlk {
            display: block;
            width: 100%;
        }

        li.inPageBreadcrumbBlk:last-child {
            display: none;
        }
    }
}

// .pgTxtNormal,
// .pgTxtBold {
//     display: block;
//     font-family: $font-agaramond;
//     font-size: $fontsize-17;
//     text-align: justify;
//     margin: 0px 10px 15px 15px;
// }

// .pgTxtBold {
//     font-family: $font-agaramondB;
//     font-size: $fontsize-18;
//     color: $color-blue-navi-bg;
//     margin: 40px 10px 20px 15px;
// }

// ul.bulletSquare {
//     list-style-type: square;
//     margin-left: 30px;
// }

// ul.bulletSquare li {
//     padding-bottom: 10px;
// }

.pgTxtNormal,
.pgTxtBold,
.pgTxtBold_L,
.pgTxtBold_XL,
.pgTxtBoldNoTopSpace {
    display: block;
    font-family: $font-agaramond;
    font-size: $fontsize-19;
    text-align: justify;
    margin: 0px 10px 20px 15px;
    color: $color-black;
    line-height: 30px;
}

.pgTxtBold {
    font-family: $font-agaramondB;
    font-size: $fontsize-20;
    margin: 40px 10px 20px 15px;
}

.pgTxtBold_L {
    font-family: $font-agaramondB;
    font-size: $fontsize-24;
    margin-bottom: 15px;
}

.pgTxtBold_XL {
    font-family: $font-agaramondB;
    font-size: $fontsize-30;
    margin-bottom: 15px;
}

.pgTxtBoldNoTopSpace {
    font-family: $font-agaramondB;
    font-size: $fontsize-20;
}

.pgTxtNormal a {
    text-decoration: none;
    color: $color-blue;
}

.pgTxtNormal a:hover {
    color: $color-black;
}

@media ($smaller-than: $breakpoint-599) {

    .pgTxtNormal,
    .pgTxtBold,
    .pgTxtBold_XL,
    .pgTxtBold_L,
    .pgTxtBoldNoTopSpace {
        font-size: $fontsize-17;
        line-height: 24px;
        margin-bottom: 15px;
    }

    .pgTxtBold,
    .pgTxtBold_XL,
    .pgTxtBold_L,
    .pgTxtBoldNoTopSpace {
        font-size: $fontsize-20;
    }
}

ul.bulletSquareOutline {
    list-style: none;
    margin-left: 15px;
    list-style-position: outside;
}

ul.bulletSquareOutline li {
    padding-bottom: 8px;
}

ul.bulletSquareOutline li::before {
    content: "\01f795";
    margin-right: 10px;
}

ul.bulletSquare {
    list-style: none;
    margin-left: 15px;
    list-style-position: outside;
}

ul.bulletSquare li {
    padding-bottom: 10px;
}

ul.bulletSquare li::before {
    content: "\00ffed";
    margin-right: 10px;
}


ul.bulletArrow {
    list-style: none;
    margin-left: 12px;
}

ul.bulletArrow li {
    padding-bottom: 8px;
}

ul.bulletArrow li::before {
    content: "\0023f5";
    margin-right: 5px;
}

ol.bulletNumber {
    margin-left: 15px;
}

ol.bulletNumber li {
    padding: 0px 0px 8px 8px;
}

ul.bulletPoints_un {
    list-style: none;
    margin: 0px 0px 30px 15px;
    font-size: $fontsize-19;

    .bp_indent_30 {
        margin-left: 30px;
        margin-bottom: 20px;
    }

    .bp_indent_60 {
        margin-left: 60px;
        margin-bottom: 25px;
    }

    .bp_indent_90 {
        margin-left: 90px;
        margin-bottom: 25px;
    }

    li.bp_squareOutline,
    li.bp_circleOutline,
    li.bp_circle,
    li.bp_circleFilled,
    li.bp_circleDouble {
        margin: 0px 0px 15px 30px;
        line-height: 30px;
    }

    li.bp_squareOutline::before,
    li.bp_circleOutline::before,
    li.bp_circle::before,
    li.bp_circleFilled::before,
    li.bp_circleDouble:before {
        color: $color-red;
        margin-left: -30px;
        padding-right: 15px;
    }

    li.bp_squareOutline::before {
        content: "\01f795";
    }

    li.bp_circleOutline::before {
        content: "\0025c9";
    }

    li.bp_circle::before {
        content: "\01f53e";
        font-size: $fontsize-16;
    }

    li.bp_circleFilled::before {
        content: "\0023fa";
    }

    li.bp_circleDouble::before {
        content: "\0025ce";
    }
}

@media ($smaller-than: $breakpoint-599) {
    ul.bulletPoints_un {
        font-size: $fontsize-17;
    }
}

//Inpage Component Styles

.inPgRightHeadResponsive {
    display: none;
    margin-top: 30px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-family: $font-times;
    font-weight: bold;
    font-size: $fontsize-26;
    color: $color-white;
    background: $color-red;
}

/*Inner Page Hero Image*/
#heroImg_buy,
#heroImg_refinance,
#heroImg_tools,
#heroImg_options,
#heroImg_career,
#heroImg_blog,
#heroImg_review,
#heroImg_contact,
#heroImg_generic {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 0px;
}

#heroImg_buy {
    background-image: url("../img/heroImg_buy.jpg");
}

#heroImg_refinance {
    background-image: url("../img/heroImg_refinance.jpg");
}

#heroImg_tools {
    background-image: url("../img/heroImg_tools.jpg");
}

#heroImg_options {
    background-image: url("../img/heroImg_options.jpg");
}

#heroImg_career {
    background-image: url("../img/heroImg_career.jpg");
}

#heroImg_blog {
    background-image: url("../img/heroImg_blog.jpg");
}

#heroImg_review {
    background-image: url("../img/heroImg_review.jpg");
}

#heroImg_contact {
    background-image: url("../img/heroImg_contact.jpg");
}

#heroImg_generic {
    background-image: url("../img/heroImg_generic.jpg");
}

@media ($smaller-than: $breakpoint-1200) {

    #heroImg_buy,
    #heroImg_refinance,
    #heroImg_tools,
    #heroImg_options,
    #heroImg_career,
    #heroImg_generic {
        background-position-y: inherit;
    }
}

@media ($smaller-than: $breakpoint-899) {

    #heroImg_buy,
    #heroImg_refinance,
    #heroImg_tools,
    #heroImg_options,
    #heroImg_career,
    #heroImg_blog,
    #heroImg_review,
    #heroImg_contact,
    #heroImg_generic {
        margin-bottom: 25px;
    }
}

//BreadCrumb Text Style
ul.bc_bg {
    display: block;
    background: $bgBlackTransparent_60;

    li.bc_col_100pc {
        display: block;
    }

    li.bc_col {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 400px);
        width: -webkit-calc(100% - 400px);
    }

    li.bc_col:last-child {
        width: 400px;

        .bcRightBlk {
            display: block;
            margin-top: 140px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            font-family: $font-times;
            font-weight: bold;
            font-size: $fontsize-28;
            color: $color-red;
            background: $bgTransparent_95;
        }
    }

    @media ($smaller-than: $breakpoint-899) {
        li.bc_col {
            width: 100%;
            margin: 0px;
            padding: 0px;
        }

        li.bc_col:last-child {
            display: none;
        }
    }

    .bcBlkDesc {
        display: block;
        margin: 120px 0px 5px 0px;

        .blkDescTxt {
            display: inline-block;
            vertical-align: top;
            font-family: $font-agaramondB;
            font-size: $fontsize-13;
            color: $color-red;
            background: $color-white;
            text-transform: uppercase;
            padding: 0px 0px 0px 12px;
            height: 25px;
            line-height: 28px;
            margin-left: 10px;
            margin-bottom: 5px;
        }

        .rightCurve {
            display: inline-block;
            vertical-align: top;
            width: 0;
            height: 25px;
            border-left: 7px solid $color-white;
            border-right: 20px solid transparent;
            border-bottom: 25px solid $color-white;
            -webkit-transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
        }

        @media ($smaller-than: $breakpoint-899) {
            .blkDescTxt {
                font-size: $fontsize-12;
            }
        }
    }

    @media ($smaller-than: $breakpoint-899) {
        .bcBlkDesc {
            margin-top: 50px;
        }
    }

    .bcHeadTxt,
    .bcHeadTxt_topTxt_noRight,
    .bcHeadTxt_generic,
    .bcHeadTxt_generic_inline,
    .bcHeadTxt_generic_inline_support {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-32;
        color: $color-white;
        padding-left: 10px;
        text-transform: capitalize;
        text-shadow: 0px 1px $color-black;
    }

    .bcHeadTxt_generic {
        margin: 112px 0px 17px 0px;
    }

    .bcHeadTxt_generic_inline {
        display: inline-block;
        vertical-align: top;
        margin: 112px 3px 17px 0px;
    }

    .bcHeadTxt_generic_inline_support {
        display: inline-block;
        vertical-align: top;
        font-family: $font-agaramond;
        margin: 120px 0px 17px 0px;
        font-size: $fontsize-30;
    }

    .bcHeadTxt_topTxt_noRight {
        margin: 0px 0px 18px 0px;
    }

    @media ($smaller-than: $breakpoint-899) {

        .bcHeadTxt,
        .bcHeadTxt_topTxt_noRight,
        .bcHeadTxt_generic,
        .bcHeadTxt_generic_inline {
            font-size: $fontsize-30;
            margin-bottom: 15px;
        }

        .bcHeadTxt_generic,
        .bcHeadTxt_generic_inline {
            margin-top: 83px;
        }

        .bcHeadTxt_generic_inline_support {
            display: none;
        }
    }
}

.Icol {
    display: block;
    width: 100%;
    margin-top: 30px;
}

ul.IIcol {
    display: block;
    margin: 30px 0px 0px 0px;
    padding: 0px;

    li.IIcol {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2 - 13px);
        width: -webkit-calc(100%/2 - 13px);
        margin-right: 25px;
    }

    li.IIcol:nth-child(even) {
        margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-1023) {
        li.IIcol {
            display: block;
            width: 100%;
            margin: 0px;
        }
    }
}

ul.IIIcol {
    display: block;
    margin: 30px 0px 0px 0px;
    padding: 0px;

    li.IIIcol {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 3 - 17px);
        width: -webkit-calc(100% / 3 - 17px);
        margin-right: 25px;
    }

    li.IIIcol:nth-child(3n + 0) {
        margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-1023) {
        li.IIIcol {
            display: block;
            width: 100%;
            margin: 0px 0px 20px 0px;
        }
    }
}

ul.IIcol_100by400 {
    display: block;

    li.IIcol_100by400 {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 430px);
        width: -webkit-calc(100% - 430px);
        margin: 30px 30px 0px 0px;
    }

    li.IIcol_100by400:last-child {
        width: 400px;
        margin: 0px;
    }

    @media ($smaller-than: $breakpoint-899) {

        li.IIcol_100by400,
        li.IIcol_100by400:last-child {
            display: block;
            width: 100%;
            margin: 0px;
        }
    }
}

.contactInfoBlk {
    display: block;
    background: $color-white;
    padding: 30px 35px 10px 35px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);

    .contactInfo {
        display: block;

        .contInfoBlkDesc {
            display: block;
            font-family: $font-agaramond;
            font-size: $fontsize-18;
            text-align: justify;
            color: $color-black;
            margin-bottom: 40px;
        }

        ul.iconDesc {
            display: block;
            margin-bottom: 35px;

            li.iconDesc {
                display: inline-block;
                vertical-align: top;
                width: 40px;
            }

            li.iconDesc:last-child {
                width: calc(100% - 40px);
                width: -webkit-calc(100% - 40px);
            }
        }

        .contInfoLocIcon {
            display: block;
            width: 40px;
            height: 50px;
            background: $locationFormRedIcon_SVG;
            background-size: 24px;
            background-position: left center;
            background-repeat: no-repeat;
        }

        .contInfoPhIcon {
            display: block;
            width: 40px;
            height: 50px;
            background: $phFormRedIcon_SVG;
            background-size: 20px;
            background-position: left center;
            background-repeat: no-repeat;
        }

        .contInfoMailIcon {
            display: block;
            width: 40px;
            height: 50px;
            background: $mailFormRedIcon_SVG;
            background-size: 24px;
            background-position: left center;
            background-repeat: no-repeat;
        }

        .contInfoTxt {
            display: block;
            font-family: $font-agaramondB;
            font-size: $fontsize-12;
            color: $color-blacklight;
            text-transform: uppercase;
            margin: 2px 0px 5px 0px;
        }

        .contInfoTxtBold {
            display: block;
            font-family: $font-agaramondB;
            font-size: $fontsize-22;
            color: $color-black;
        }
    }
}

//Inpage Component Styles

.inPgRightHeadResponsive {
    display: none;
    margin-top: 30px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-family: $font-times;
    font-weight: bold;
    font-size: $fontsize-26;
    color: $color-white;
    background: $color-red;
}

@media ($smaller-than: $breakpoint-899) {
    .inPgRightHeadResponsive {
        display: block;
    }
}

/*Refinance*/
.refin_3col_prepare,
.refin_4col_fixedRate,
.refin_5col_lower,
.refin_5col_payoff,
.refin_6col_consolidate {
    display: block;
    margin: -10px 0px 20px 15px;

    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        margin: 0px;
        padding: 0px;
        border: 1px solid $color-blackbrown;
        background-image: $bgTexure;
        color: $color-black;
        font-size: $fontsize-17;

        td {
            vertical-align: top;
            padding: 10px 10px;
            border-bottom: 1px solid $color-lightblack-dark;
        }

        tr:first-child td {
            background: $color-blackbrown;
            background-image: $bgTexure;
            font-family: $font-agaramondB;
            color: $color-white;
            border-bottom: 1px solid $color-blackbrown;
        }

        tr:last-child td {
            border-bottom: none;
        }
    }
}

.refin_3col_prepare {
    table {

        td:first-child,
        td:nth-child(2),
        td:last-child {
            width: calc(100% / 3);
            width: -webkit-calc(100% / 3);
        }
    }
}

.refin_4col_fixedRate {
    table {
        td:first-child {
            width: calc(100% - 550px);
            width: -webkit-calc(100% - 550px);
        }

        td:nth-child(2) {
            width: 140px;
        }

        td:nth-child(3),
        td:nth-child(4) {
            width: 180px;
        }
    }
}

.refin_5col_lower {
    table {
        td:first-child {
            width: auto;
        }

        td:nth-child(2),
        td:nth-child(5) {
            width: 150px;
        }

        td:nth-child(3),
        td:nth-child(4) {
            width: 100px;
        }
    }
}

.refin_5col_payoff {
    table {
        td:first-child {
            width: auto;
        }

        td:nth-child(2),
        td:nth-child(3),
        td:nth-child(4),
        td:nth-child(5) {
            width: 120px;
        }
    }
}

.refin_6col_consolidate {
    table {
        td:first-child {
            width: auto;
        }

        td:nth-child(2),
        td:nth-child(3),
        td:nth-child(4),
        td:nth-child(5),
        td:last-child {
            width: 120px;
        }
    }
}

/*Loan Process*/
.loanProcessBlk {
    display: block;
    margin-top: 30px;

    .loanProcessHead {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-32;
        color: $color-black;
        text-align: center;
        margin-bottom: 40px;
    }

    ul.lp_headblk {
        display: block;

        li.lp_headblk {
            display: inline-block;
            vertical-align: top;
            height: 40px;
        }

        li.lp_headblk:first-child,
        li.lp_headblk:last-child {
            width: 80px;
        }

        li.lp_headblk:nth-child(2) {
            width: calc(100% - 160px);
            width: -webkit-calc(100% - 160px);
            text-align: center;
            border-radius: 15px 15px 0px 0px;
            font-family: $font-agaramondB;
            font-size: $fontsize-20;
            color: $color-white;
            background: $color-red;
            line-height: 45px;
        }

        .lp_leftCurveBlk {
            width: 20px;
            height: 20px;
            position: relative;
            float: right;
            top: 20px;
            background: $color-red;

            .lp_leftCurve {
                width: 20px;
                height: 20px;
                background-image: $bgTexure;
                background: $color-offwhitelight;
                border-bottom-right-radius: 15px;
            }
        }

        .lp_rightCurveBlk {
            width: 20px;
            height: 20px;
            position: relative;
            float: left;
            top: 20px;
            background: $color-red;

            .lp_rightCurve {
                width: 20px;
                height: 20px;
                background-image: $bgTexure;
                background: $color-offwhitelight;
                border-bottom-left-radius: 15px;
            }
        }
    }

    .lpContentBox {
        display: block;
        background: $color-white;
        border-top: 1px solid $color-red;
        padding: 25px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        margin-bottom: 25px;

        .lpContent {
            display: block;
            font-family: $font-agaramondB;
            font-size: $fontsize-18;
            color: $color-black;
            text-align: center;
        }

        .lpBtnBlk {
            display: block;
            text-align: center;
            margin: 30px 0px 10px 0px;

            .lpButton {
                padding: 8px 15px;
                font-family: $font-agaramondB;
                font-size: $fontsize-18;
                color: $color-white;
                text-transform: capitalize;
                border: 0px;
                background: $color-red;
                outline: none;
                border-radius: 7px;
            }

            .lpButton:hover {
                cursor: pointer;
                background: $color-red-light;
            }
        }
    }
}

/*Mortgage Rates*/
.mortgageRateBlk {
    display: block;

    .mr_heading {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-30;
        color: $color-red-dark;
        text-align: center;
    }

    .mr_noteTxt {
        display: block;
        font-family: $font-agaramond;
        font-size: $fontsize-16;
        color: $color-lightblack;
        margin: -3px 0px 15px 0px;
        text-align: center;
    }

    .rateTip {
        display: inline-block;
        vertical-align: top;
        width: 15px;
        height: 15px;
        background: $infoFormIcon_SVG;
        background-size: 15px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .rateTip:hover {
        cursor: pointer;
    }

    ul.rateBlock {
        display: block;
        background: $color-white;
        padding: 15px 20px 15px 20px;
        border-radius: 8px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        margin-bottom: 25px;

        li.rateBlock {
            display: inline-block;
            vertical-align: top;
            margin-right: 20px;
        }

        li.rateBlock:first-child {
            width: calc(100% - 330px);
            width: -webkit-calc(100% - 330px);
        }

        li.rateBlock:nth-child(2),
        li.rateBlock:nth-child(3) {
            width: 85px;
        }

        li.rateBlock:nth-child(4) {
            width: 100px;
            margin-right: 0px;
            padding-top: 10px;
        }

        .rateHead,
        .rateResult {
            display: block;
            font-family: $font-agaramondB;
            font-size: $fontsize-20;
            color: $color-blackbrown;
        }

        .rateHead {
            margin-bottom: 2px;
            color: $color-lighterblack;
            font-size: $fontsize-14;
        }

        .rateResult {
            font-size: $fontsize-26;

            .symbol {
                position: relative;
                font-size: $fontsize-9;
                color: $color-lightblack;
                left: 3px;
            }
        }

        .rate_button {
            padding: 8px 12px 5px 12px;
            font-family: $font-agaramondB;
            font-size: $fontsize-17;
            color: $color-white;
            text-transform: capitalize;
            border: 0px;
            background: $color-red-light;
            outline: none;
            border-radius: 5px;
        }

        .rate_button:hover {
            cursor: pointer;
            background: $color-red-light;
        }
    }
}

/*About Us Page Styles*/
ul.aboutInpgContainer {
    display: block;
    margin: 70px 0px;

    li.aboutContainer {
        display: inline-block;
        vertical-align: top;
    }

    li.aboutContainer {
        width: 565px;
    }

    li.aboutContainer:last-child {
        width: calc(100% - 565px);
        width: -webkit-calc(100% - 565px);
        padding-left: 60px;
    }

    @media ($smaller-than: $breakpoint-1023) {
        li.aboutContainer {
            width: 400px;
        }

        li.aboutContainer:last-child {
            width: calc(100% - 400px);
            width: -webkit-calc(100% - 400px);
            padding-left: 30px;
        }
    }

    @media ($smaller-than: $breakpoint-699) {

        li.aboutContainer,
        li.aboutContainer:last-child {
            display: block;
            width: 100%;
            padding: 0px;
        }
    }

    .aboutImgBlk {
        display: block;
        width: 100%;

        .aboutImage {
            width: 465px;
            height: 650px;
            background-image: url("../img/about.jpg");
            background-repeat: no-repeat;
            background-size: cover;
        }

        @media ($smaller-than: $breakpoint-1023) {
            .aboutImage {
                width: 300px;
                height: 450px;
            }
        }

        @media ($smaller-than: $breakpoint-699) {
            .aboutImage {
                width: 85%;
                height: 450px;
            }
        }

        .expBlk {
            float: right;
            width: 200px;
            aspect-ratio: 1/1;
            background: $color-white;
            border: 1px solid $color-red-lightest;
            transform: translate(0%, 100%);
            padding: 12px;

            .expBlkContent {
                width: 100%;
                aspect-ratio: 1/1;
                background: $color-red;

                .txtExpNumber {
                    display: block;
                    text-align: center;
                    padding-top: 30px;
                    font-family: $font-agaramondB;
                    font-size: $fontsize-80;
                    color: $color-white;

                    .symbolFont {
                        font-size: $fontsize-6;
                    }
                }

                .txtExp {
                    display: block;
                    text-align: center;
                    font-family: $font-agaramondB;
                    font-size: $fontsize-16;
                    color: $color-white;
                    text-transform: uppercase;
                    margin-top: -15px;
                }

                @media ($smaller-than: $breakpoint-1023) {
                    .txtExpNumber {
                        padding-top: 20px;
                        font-size: $fontsize-48;
                    }

                    .txtExp {
                        font-size: $fontsize-14;
                        margin-top: -5px;
                    }
                }
            }
        }

        @media ($smaller-than: $breakpoint-1023) {
            .expBlk {
                width: 150px;
                aspect-ratio: 1/1;
            }
        }
    }

    .aboutContentBlk {
        display: block;

        .abt_headTxt {
            display: block;
            font-family: $font-agaramondB;
            font-size: $fontsize-20;
            color: $color-red;
            text-transform: uppercase;
            margin: 25px 0 15px 0;
        }

        .abt_heroTxt {
            display: block;
            font-family: $font-times;
            font-weight: bold;
            font-size: $fontsize-40;
            color: $color-blue-darker;
            text-transform: uppercase;
            margin-bottom: 40px;
        }

        .abt_pointHeadTxt {
            display: block;
            font-family: $font-agaramondB;
            font-size: $fontsize-18;
            color: $color-red;
            margin-bottom: 5px;
        }

        .abt_pointTxt {
            display: block;
            font-family: $font-agaramond;
            font-size: $fontsize-17;
            color: $color-blackbrown;
            margin-bottom: 24px;
        }

        @media ($smaller-than: $breakpoint-1023) {
            .abt_headTxt {
                font-size: $fontsize-18;
                margin: 0px 0 10px 0px;
            }

            .abt_heroTxt {
                font-size: $fontsize-24;
                margin-bottom: 20px;
            }

            .abt_pointHeadTxt {
                font-size: $fontsize-17;
                margin-bottom: 3px;
            }

            .abt_pointTxt {
                font-size: $fontsize-16;
                margin-bottom: 20px;
            }
        }

        @media ($smaller-than: $breakpoint-699) {
            .abt_headTxt {
                margin: 30px 0 5px 0px;
            }
        }

        ul.abt_uspPoint {
            display: block;
            margin-top: 30px;

            li.abt_uspPoint {
                display: inline-block;
                vertical-align: top;
                text-transform: uppercase;
                width: calc(100% / 2);
                width: -webkit-width (100% /2);
                font-family: $font-agaramondB;
                font-size: $fontsize-16;
                color: $color-blackbrown;
                margin-bottom: 10px;
            }

            li.abt_uspPoint::before {
                content: "\002b9a";
                margin-right: 10px;
                color: $color-blackbrown-light;
                font-size: $fontsize-20;
            }

            @media ($smaller-than: $breakpoint-1023) {
                li.abt_uspPoint {
                    font-size: $fontsize-14;
                }
            }
        }

        .abt_button {
            border: 2px solid $color-red-lightest;
            padding: 24px 46px;
            font-family: $font-agaramondB;
            font-size: $fontsize-16;
            color: $color-blue-darker;
            text-transform: uppercase;
            margin-top: 23px;
            background: transparent;
            outline: none;
            transition-property: "background";
            transition-duration: 0.5s;
            -webkit-transition: 0.5s;
            transition: 0.5s;
        }

        .abt_button:hover {
            cursor: pointer;
            background: $color-blackbrown;
            border-color: $color-blackbrown;
            color: $color-white;
        }

        @media ($smaller-than: $breakpoint-999) {
            .abt_button {
                padding: 10px 22px;
                font-size: $fontsize-15;
            }
        }
    }
}

/*Loan Officers*/
.lo_pgBlk {
    display: block;
    margin-top: 25px;

    ul.filterLo {
        display: block;
        background: $color-white;
        padding: 20px 20px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
        margin-bottom: 25px;

        li.filterLo {
            display: inline-block;
            vertical-align: top;
            width: 300px;
            margin-right: 10px;
        }

        li.filterLo:last-child {
            margin-right: 0px;
        }

        @media ($smaller-than: $breakpoint-599) {
            li.filterLo {
                width: 225px;
            }
        }
    }

    select {
        width: 100%;
        font-family: $font-agaramond;
        font-size: $fontsize-16;
        color: $color-black;
        border: 1px solid $color-offblack;
        border-radius: 3px;
        outline: 0;
        padding: 0px 15px;
        height: 40px;
        background: $selectarrow_blackIcon_SVG;
        background-position: right 3% center;
        background-color: $color-white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }

    select:hover {
        cursor: pointer;
        background: $selectarrow_blueIcon_SVG;
        background-position: right 3% center;
        background-color: $color-white;
    }

    .selectFilterLO>select {
        height: 50px;
        border-color: $color-lighterblack;
        font-family: $font-agaramondB;
        font-size: $fontsize-18;
        border-radius: 5px;
        background: $selectarrow_blackIcon_SVG $color-offwhitelight;
        background-position: right 3% center;
        background-size: 20px;
    }

    .selectFilterLO>select:hover {
        cursor: pointer;
        background: $selectarrow_redIcon_SVG;
        background-position: right 3% center;
        background-color: $color-white;
        background-size: 20px;
    }

    .filterLo_but_blk {
        display: inline-block;
        vertical-align: top;
        width: 100px;

        .filterLo_but {
            display: block;
            width: 100%;
            height: 50px;
            border: none;
            border-radius: 5px;
            font-family: $font-agaramondB;
            font-size: $fontsize-18;
            color: $color-white;
            outline: none;
            background: $color-red;
            text-align: center;
            margin: 0 auto;
        }

        .filterLo_but:hover {
            cursor: pointer;
        }
    }

    .loResults {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-30;
        color: $color-blue-dark;
        text-align: center;
        margin: 35px 0px 20px 0px;
    }

    ul.lo_display_blk {
        display: block;

        li.lo_display_blk {
            display: inline-block;
            vertical-align: top;
            width: calc(100% / 3 - 14px);
            width: -webkit-calc(100% / 3 - 14px);
            margin: 0px 21px 30px 0px;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
            border-top: 2px solid $color-red-lighter;
            padding: 20px 20px 5px 20px;
            background: $color-white;
            min-height: 200px;
        }

        li.lo_display_blk:nth-child(3n + 0) {
            margin-right: 0px;
        }

        @media ($smaller-than: $breakpoint-1023) {
            li.lo_display_blk:nth-child(even) {
                margin-right: 0px;
            }

            li.lo_display_blk {
                width: calc(100% / 2 - 14px);
                width: -webkit-calc(100% / 2 - 14px);
            }
        }

        @media ($smaller-than: $breakpoint-699) {
            li.lo_display_blk {
                display: block;
                width: 100%;
                margin-right: 0px;
            }
        }
    }

    .loName {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-24;
        color: $color-red-dark;
        margin-bottom: 15px;
    }

    ul.lo_details {
        display: block;

        li.lo_details {
            display: inline-block;
            vertical-align: top;
            width: calc(100% / 3 - 14px);
            width: -webkit-calc(100% / 3 - 14px);
            margin-bottom: 15px;
            font-size: $fontsize-17;
        }

        li.lo_details:nth-child(odd) {
            width: 105px;
            font-family: $font-agaramondB;
            color: $color-black;
        }

        li.lo_details:nth-child(even) {
            width: calc(100% - 105px);
            width: -webkit-calc(100% - 105px);
            font-family: $font-agaramond;
            color: $color-blackbrown;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        li.lo_details a {
            text-decoration: none;
            color: $color-blackbrown;
        }

        li.lo_details:hover {
            cursor: pointer;
        }
    }
}

/*Blogs*/
ul.blog_IIIcol {
    display: block;

    li.blog_IIIcol {
        display: inline-block;
        vertical-align: top;
        border-top: 0.25px solid $color-offblack-light;
        width: calc(100% / 3 - 17px);
        width: -webkit-calc(100%/3 - 17px);
        margin-right: 25px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        padding-bottom: 40px;
    }

    li.blog_IIIcol:last-child {
        margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-999) {

        li.blog_IIIcol,
        li.blog_IIIcol:last-child {
            display: block;
            width: 50%;
            margin: 0px auto 25px auto;
        }
    }

    @media ($smaller-than: $breakpoint-599) {

        li.blog_IIIcol,
        li.blog_IIIcol:last-child {
            width: 100%;
        }
    }

    .blogImg1 {
        display: block;
        background: url(../img/blog_img1.jpg);
        height: 250px;
        background-size: cover;
        margin-bottom: 15px;
    }

    .blogImg2 {
        display: block;
        background: url(../img/blog_img2.jpg) no-repeat;
        height: 250px;
        background-size: cover;
        margin-bottom: 15px;
    }

    .blogImg3 {
        display: block;
        background: url(../img/blog_img3.jpg) no-repeat;
        height: 250px;
        background-size: cover;
        margin-bottom: 15px;
    }

    .blogdateMonthBlk {
        position: relative;
        float: right;
        top: 185px;
        right: 20px;
        width: 70px;
        height: 80px;
        background: $color-red;
        text-align: center;

        .blogDate {
            font-family: $font-times;
            font-size: $fontsize-30;
            font-weight: bold;
            color: $color-white;
            margin: 12px 0px 0px 0px;
        }

        .blogMonth {
            font-family: $font-times;
            font-size: $fontsize-18;
            font-weight: bold;
            color: $color-white;
            text-transform: uppercase;
        }
    }

    .blogColHead {
        display: block;
        font-family: $font-times;
        font-size: $fontsize-24;
        font-weight: bold;
        color: $color-blackbrown;
        padding: 30px 35px 20px 35px;
    }

    .blogColDesc {
        display: block;
        font-family: $font-times;
        font-size: $fontsize-17;
        color: $color-lightblack;
        padding: 0px 35px 10px 35px;
        text-align: justify;
    }

    .blogReadMore {
        display: block;
        padding-left: 35px;
        text-decoration: none;
    }

    .blogReadMore .readMoreLink {
        font-family: $font-agaramondB;
        font-size: $fontsize-18;
        color: $color-red;
        text-transform: capitalize;
    }

    .blogReadMore .readMoreLink:hover {
        color: $color-blackbrown-lighter;
    }

    .blogReadMore .readMoreLink::after {
        content: "\0000bb";
        margin-left: 10px;
        font-size: $fontsize-30;
        color: $color-red;
    }

    @media ($smaller-than: $breakpoint-1200) {
        .blogColHead {
            font-size: $fontsize-20;
            padding-bottom: 10px;
        }

        .blogColDesc {
            font-size: $fontsize-15;
        }

        .blogReadMore .readMoreLink {
            font-size: $fontsize-16;
        }
    }
}

/*Reviews*/
.reviewBlk {
    display: block;

    ul.IIcol_Review {
        display: block;
        margin: 30px 0px 0px 0px;
        padding: 0px;

        li.IIcol_Review {
            display: inline-block;
            vertical-align: top;
            width: calc(100% / 2 - 13px);
            width: -webkit-calc(100%/2 - 13px);
            margin: 0px 25px 25px 0px;
        }

        li.IIcol_Review:nth-child(even) {
            margin-right: 0px;
        }

        @media ($smaller-than: $breakpoint-1023) {
            li.IIcol_Review {
                display: block;
                width: 100%;
                margin: 0px;
            }
        }
    }

    .reviewContentBlkLeft,
    .reviewContentBlkRight {
        display: block;
        border-radius: 25px 0px 0px 0px;
        background: $color-offwhitelight;
        background-image: $bgTexure;
        // border: 1px solid $color-lighterwhite;

        ul.reviewPhotoContent {
            display: block;
            padding: 20px;

            li.reviewPhotoContentLeft,
            li.reviewPhotoContentRight {
                display: inline-block;
                vertical-align: top;
            }

            li.reviewPhotoContentLeft:first-child {
                width: 100px;
                height: 100px;
                margin-right: 20px;
                border-radius: 25px;
                background: $color-red;
                font-family: $font-agaramondB;
                font-size: $fontsize-30;
                color: $color-white;
                text-align: center;
                line-height: 100px;
            }

            li.reviewPhotoContentLeft:last-child {
                width: calc(100% - 120px);
                width: -webkit-calc(100% - 120px);
            }

            li.reviewPhotoContentRight:first-child {
                width: calc(100% - 120px);
                width: -webkit-calc(100% - 120px);
                margin-right: 20px;
            }

            li.reviewPhotoContentRight:last-child {
                width: 100px;
                height: 100px;
                border-radius: 25px;
                background: $color-red;
                font-family: $font-agaramondB;
                font-size: $fontsize-30;
                color: $color-white;
                text-align: center;
                line-height: 100px;
            }

            @media ($smaller-than: $breakpoint-699) {

                li.reviewPhotoContentLeft:first-child,
                li.reviewPhotoContentRight:last-child {
                    display: none;
                }

                li.reviewPhotoContentLeft:last-child,
                li.reviewPhotoContentRight:first-child {
                    display: block;
                    width: 100%;
                }
            }

            .reviewerPhoto {
                width: 100px;
                height: 100px;
                border-radius: 25px;
                background: $color-white;
                background-image: $bgTexure;
            }

            .reviewerPhoto img {
                max-width: 100%;
                max-height: 100%;
                min-height: 100%;
                min-width: 100%;
                border-radius: 25px;
            }

            .reviewerName {
                display: block;
                font-family: $font-agaramondB;
                font-size: $fontsize-24;
                color: $color-red-dark;
                margin-bottom: 15px;
                border-bottom: 1px solid $color-white;
                padding-bottom: 2px;
                text-transform: uppercase;
            }

            ul.ratingDateBlk {
                display: block;
                margin-bottom: 25px;

                li.ratingDateBlk {
                    display: inline-block;
                    vertical-align: top;
                    width: calc(100% / 2 - 13px);
                    width: -webkit-calc(100%/2 - 13px);
                    margin-right: 25px;
                }

                li.ratingDateBlk:nth-child(odd) {
                    margin-top: -3px;
                }

                li.ratingDateBlk:nth-child(even) {
                    margin-right: 0px;
                    text-align: right;
                    font-family: $font-agaramond;
                    font-size: $fontsize-16;
                    color: $color-blackbrown;
                }

                .rating_sprite_red {
                    height: 22px;
                    line-height: 0px;
                    overflow: hidden;
                    text-indent: -999em;
                    width: 127px;
                    background-image: $starRating_SVG;
                }

                .rating_sprite_red_bg {
                    float: left;
                    background-image: $starRatingBg_SVG;
                    background-position: 0 100%;
                    height: 22px;
                }
            }

            .reviewDesc {
                display: block;
                font-family: $font-agaramondB;
                font-size: $fontsize-18;
                color: $color-black;
                margin-bottom: 10px;
            }

            .reviewContent {
                display: block;
                font-family: $font-agaramond;
                font-size: $fontsize-17;
                color: $color-black;
                margin-bottom: 10px;
            }
        }
    }

    .reviewContentBlkRight {
        border-radius: 0px 25px 0px 0px;
    }

    @media ($smaller-than: $breakpoint-1023) {
        .reviewContentBlkLeft {
            background: none;
            background-image: linear-gradient(to right,
                    rgba(219, 219, 219, 1) 50%,
                    rgba(0, 0, 0, 0));
        }

        .reviewContentBlkRight {
            background: none;
            background-image: linear-gradient(to left,
                    rgba(219, 219, 219, 1) 50%,
                    rgba(0, 0, 0, 0));
        }
    }

    ul.reviewCurveArrowLeft,
    ul.reviewCurveArrowRight {
        display: block;
        height: 40px;
        margin-bottom: 35px;

        li.reviewCurveArrowLeft,
        li.reviewCurveArrowRight {
            display: inline-block;
            vertical-align: top;
        }

        li.reviewCurveArrowLeft:nth-child(1) {
            width: 40px;
            background: $color-lighterwhite;
        }

        li.reviewCurveArrowLeft:nth-child(2) {
            width: calc(100% - 40px);
            width: -webkit-calc(100% - 40px);
        }

        li.reviewCurveArrowRight:nth-child(1) {
            width: calc(100% - 40px);
            width: -webkit-calc(100% - 40px);
        }

        li.reviewCurveArrowRight:nth-child(2) {
            width: 40px;
            background: $color-lighterwhite;
        }

        .reviewLeftCurve {
            width: 40px;
            height: 40px;
            border-top-left-radius: 100%;
            background: $color-white;
            background-image: $bgTexure;
        }

        .reviewRightCurve {
            width: 40px;
            height: 40px;
            border-top-right-radius: 100%;
            background: $color-white;
            background-image: $bgTexure;
        }
    }

    @media ($bigger-than: $breakpoint-1023) {

        ul.reviewCurveArrowLeft,
        ul.reviewCurveArrowRight {
            display: none;
        }
    }
}

/*Branch Location*/
.branchLocationBlk {
    display: block;
    margin-top: 30px;

    .loanProcessHead {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-32;
        color: $color-black;
        text-align: center;
        margin-bottom: 40px;
    }

    ul.lp_headblk {
        display: block;

        li.lp_headblk {
            display: inline-block;
            vertical-align: top;
            height: 40px;
        }

        li.lp_headblk:first-child,
        li.lp_headblk:last-child {
            width: 80px;
        }

        li.lp_headblk:nth-child(2) {
            width: calc(100% - 160px);
            width: -webkit-calc(100% - 160px);
            text-align: center;
            border-radius: 15px 15px 0px 0px;
            font-family: $font-agaramondB;
            font-size: $fontsize-20;
            color: $color-white;
            background: $color-red;
            line-height: 45px;
        }

        .lp_leftCurveBlk {
            width: 20px;
            height: 20px;
            position: relative;
            float: right;
            top: 20px;
            background: $color-red;

            .lp_leftCurve {
                width: 20px;
                height: 20px;
                background-image: $bgTexure;
                background: $color-offwhitelight;
                border-bottom-right-radius: 15px;
            }
        }

        .lp_rightCurveBlk {
            width: 20px;
            height: 20px;
            position: relative;
            float: left;
            top: 20px;
            background: $color-red;

            .lp_rightCurve {
                width: 20px;
                height: 20px;
                background-image: $bgTexure;
                background: $color-offwhitelight;
                border-bottom-left-radius: 15px;
            }
        }
    }

    .blContentBox {
        display: block;
        background: $color-white;
        border-top: 1px solid $color-red;
        padding: 25px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        margin-bottom: 25px;

        .bl_head {
            display: block;
            font-family: $font-agaramondB;
            font-size: $fontsize-18;
            color: $color-red-dark;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .bl_city {
            display: block;
            font-family: $font-agaramondB;
            font-size: $fontsize-16;
            color: $color-blue-dark;
            font-weight: bold;
            margin-bottom: 5px;
            text-transform: uppercase;
        }

        .bl_content {
            display: block;
            font-family: $font-agaramondB;
            font-size: $fontsize-16;
            color: $color-lightblack-dark;

            .state {
                display: block;
            }

            ul.locIconDirection {
                display: block;
                margin-top: 10px;

                li.locIconDirection {
                    display: inline-block;
                    vertical-align: top;
                    width: calc(100% - 22px);
                    width: -webkit-calc(100% - 22px);
                    font-weight: normal;
                }

                li.locIconDirection:nth-child(1) {
                    width: 22px;
                    aspect-ratio: 1/1;
                    background: $locationIcon_SVG;
                    background-size: 14px;
                    background-position: top left;
                }

                li.locIconDirection a {
                    color: $color-blue;
                }

                li.locIconDirection a:hover {
                    color: $color-lightblack-dark;
                }
            }
        }

        .lpBtnBlk {
            display: block;
            text-align: center;
            margin: 30px 0px 10px 0px;

            .lpButton {
                padding: 8px 15px;
                font-family: $font-agaramondB;
                font-size: $fontsize-18;
                color: $color-white;
                text-transform: capitalize;
                border: 0px;
                background: $color-red;
                outline: none;
                border-radius: 7px;
            }

            .lpButton:hover {
                cursor: pointer;
                background: $color-red-light;
            }
        }
    }
}

//Contact Form
.contactForm,
.contactFormNoShadow {
    display: block;
    padding: 25px;
    background: $color-white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);

    .contactForm_Icol {
        display: block;
        margin-bottom: 20px;
    }

    ul.contactForm_IIcol {
        display: block;
        margin: 0px;
        padding: 0px;

        li.contactForm_IIcol {
            display: inline-block;
            vertical-align: top;
            width: calc(100% / 2 - 13px);
            width: -webkit-calc(100%/2 - 13px);
            margin-right: 25px;
            margin-bottom: 25px;
        }

        li.contactForm_IIcol:nth-child(even) {
            margin-right: 0px;
        }

        @media ($smaller-than: $breakpoint-799) {
            li.contactForm_IIcol {
                display: block;
                width: 100%;
                margin-right: 0px;
            }
        }
    }

    .contactFormHead,
    .contactFormHead_Error {
        display: inline-block;
        vertical-align: top;
        font-family: $font-agaramondB;
        font-size: $fontsize-18;
        color: $color-blackbrown;
        margin-bottom: 3px;
    }

    .contactFormHead_Error {
        color: $color-alert;
    }

    .contactFormMandatory {
        display: inline-block;
        vertical-align: top;
        margin-left: 3px;
        height: 3px;
        font-family: $font-agaramondB;
        font-size: $fontsize-30;
        color: $color-alert;
    }

    ::-webkit-input-placeholder {
        color: $color-lightblack;
    }

    :-moz-placeholder {
        color: $color-lightblack;
    }

    ::-moz-placeholder {
        color: $color-lightblack;
    }

    :-ms-input-placeholder {
        color: $color-lightblack;
    }

    select:required:invalid {
        color: $color-disable;
    }

    option {
        color: $color-lightblack;
    }

    select::-ms-expand {
        display: none;
    }

    select {
        width: 100%;
        font-family: $font-agaramond;
        font-size: $fontsize-18;
        color: $color-black;
        border: 1px solid $color-lighterblack;
        border-radius: 3px;
        outline: 0;
        padding: 0px 25px 0px 55px;
        height: 45px;
        background: $selectarrow_blackIcon_SVG;
        background-position: right 3% center;
        background-color: $color-white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }

    select:hover {
        cursor: pointer;
        background: $selectarrow_blueIcon_SVG;
        background-position: right 3% center;
        background-color: $color-white;
    }

    input[type="text"],
    textarea {
        display: block;
        font-family: $font-agaramond;
        font-size: $fontsize-18;
        color: $color-black;
        width: 100%;
        padding: 0px 10px 0px 55px;
        height: 45px;
        border: 1px solid $color-lighterblack;
        border-radius: 3px;
        background: $color-white;
        outline: 0;
    }

    textarea {
        padding-top: 10px;
    }

    .contactFormUserIcon,
    .contactFormMailIcon,
    .contactFormPhIcon,
    .contactFormNoteIcon,
    .contactFormHastagIcon,
    .contactFormTypeIcon,
    .contactFormHomeIcon,
    .contactFormLocationIcon {
        position: absolute;
        float: left;
        width: 45px;
        height: 43px;
        border-right: 1px solid $color-lighterblack;
        margin: 1px 0 0 1px;
        border-radius: 3px 0 0 3px;
    }

    .contactFormUserIcon {
        background: $userFormBlackIcon_SVG $color-red-head-bg;
        background-size: 17px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .contactFormMailIcon {
        background: $mailFormBlackIcon_SVG $color-red-head-bg;
        background-size: 17px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .contactFormPhIcon {
        background: $phFormBlackIcon_SVG $color-red-head-bg;
        background-size: 10px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .contactFormNoteIcon {
        background: $noteFormBlackIcon_SVG $color-red-head-bg;
        background-size: 17px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .contactFormHastagIcon {
        background: $numberFormBlackIcon_SVG $color-red-head-bg;
        background-size: 12px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .contactFormTypeIcon {
        background: $typeFormBlackIcon_SVG $color-red-head-bg;
        background-size: 16px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .contactFormHomeIcon {
        background: $homeFormBlackIcon_SVG $color-red-head-bg;
        background-size: 17px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .contactFormLocationIcon {
        background: $locationFormBlackIcon_SVG $color-red-head-bg;
        background-size: 14px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .contactForm_btn_label {
        display: block;
        margin-bottom: 15px;

        .contactFormBtn {
            display: inline-block;
            vertical-align: middle;
            padding: 0px 29px;
            height: 45px;
            margin-right: 8px;
            border: 0px solid $color-red;
            border-radius: 5px;
            font-family: $font-agaramondB;
            font-size: $fontsize-20;
            color: $color-white;
            outline: none;
            background: $color-red;
            text-align: center;
        }

        .contactFormBtn:hover {
            background: $color-blue-navi;
            cursor: pointer;
        }

        .contactFormBtn[disabled],
        .contactFormBtn[disabled]:hover,
        .contactFormBtn.disabled,
        .contactFormBtn.disabled:hover {
            background: $color-lightblack;
            color: $color-offblack;
            cursor: default;
            text-shadow: none;
            position: static;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            border: 0px dotted $color-disable;
        }
    }
}

.contactFormNoShadow {
    box-shadow: none;
    background: transparent;
    padding: 5px 20px 20px 18px;
}