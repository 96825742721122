@import "support";

.headerSiddhi {
    display: block;
    width: 100%;
    visibility: visible;
    left: 0px;
    top: 0px;
    position: absolute;
    z-index: 20;
    background: -webkit-linear-gradient(to bottom,
            rgba(255, 255, 255, 1) 78%,
            rgba(255, 255, 255, 0) 22%);
    background: -moz-linear-gradient(to bottom,
            rgba(255, 255, 255, 1) 78%,
            rgba(255, 255, 255, 0) 22%);
    background: linear-gradient(to bottom,
            rgba(255, 255, 255, 1) 78%,
            rgba(255, 255, 255, 0) 22%);
    height: 148px;

    .logo {
        display: inline-block;
        vertical-align: top;

        .logoPositionHome {
            height: 65px;
            width: 200px;
            margin: 10px 0px 10px 20px;
            background-position: left center;
            background-size: 115px;
        }
    }

    // @media ($smaller-than: $breakpoint-1023) {
    //     .logo {
    //         display: none;
    //     }
    // }

    .topRight_fixed_blk {
        position: absolute;
        width: 1px;
        margin: 0px auto;
        z-index: 21;
        top: 0px;
        float: right;
    }

    // @media ($smaller-than: $breakpoint-1200) {
    //     .topRight_fixed_blk {
    //         width: 100%;
    //         // margin: 0px 10;
    //     }
    // }

    .headerTopRight {
        float: right;
        margin-top: 22px;

        ul.header_phone_blk {
            display: inline-block;
            vertical-align: top;
            margin: 0px 0px 0px 15px;
            padding: 5px 0 3px 20px;

            li.header_ph_icon {
                display: inline-block;
                vertical-align: top;
                height: 43px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                border: 1px solid $color-red-light;
                background: $profilePhIcon_SVG;
                background-position: center center;
                background-size: 20px;
                margin-top: -3px;
            }

            li.call_no_blk {
                display: inline-block;
                vertical-align: top;
                margin-left: 10px;

                .callus {
                    display: block;
                    font-family: $font-agaramond;
                    font-size: $fontsize-13;
                    color: $color-red;
                    margin-bottom: 3px;
                }

                .phNo {
                    display: block;
                    font-family: $font-agaramondB;
                    font-size: $fontsize-18;
                    color: $color-blue-darker;
                }
            }
        }

        @media ($smaller-than: $breakpoint-1023) {
            ul.header_phone_blk {
                display: none;
            }
        }



        ul.header_Account_blk {
            display: inline-block;
            vertical-align: top;
            margin: 0px 0px 0px 15px;
            padding: 5px 0 3px 20px;

            li.header_profile_img {
                display: inline-block;
                vertical-align: top;
                height: 43px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                border: 1px solid $color-red-light;
                background: $profileDefaultWhiteIcon_SVG;
                background-position: center bottom;
                background-size: 28px;
                margin-top: -3px;
            }

            @media ($smaller-than: $breakpoint-599) {
                li.header_profile_img {
                    display: none;
                }
            }

            li.header_nameAcc_blk {
                display: inline-block;
                vertical-align: top;
                margin-left: 10px;

                .headerProfileName {
                    display: block;
                    width: 100px;
                    font-family: $font-agaramond;
                    font-size: $fontsize-13;
                    color: $color-red;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .headerAccount {
                    display: block;

                    .accountnav {
                        overflow: hidden;

                        .arrow_down {
                            display: inline-block;
                            vertical-align: top;
                            margin-top: 9px;
                            margin-left: 7px;
                            width: 0;
                            height: 0;
                            border-left: 7px solid transparent;
                            border-right: 7px solid transparent;
                            border-top: 7px solid $color-blue-darker;
                        }

                        .loginIcon,
                        .profileInfoIcon,
                        .supportIcon,
                        .fgtpasswordIcon,
                        .logoutNewIcon {
                            display: inline-block;
                            vertical-align: top;
                            height: 20px;
                            width: 20px;
                            margin-right: 9px;
                        }

                        .loginIcon {
                            background: $loginSignupIcon_SVG;
                            background-size: 18px;
                            background-position: center center;
                        }

                        .profileInfoIcon {
                            background: $profileInfoIcon_SVG;
                            background-size: 18px;
                            background-position: center center;
                        }

                        .supportIcon {
                            background: $supportIcon_SVG;
                            background-size: 18px;
                            background-position: center center;
                        }

                        .fgtpasswordIcon {
                            background: $fgtpswdIcon_SVG;
                            background-size: 16px;
                            background-position: center center;
                        }

                        .logoutNewIcon {
                            background: $logout_offwhite_Icon_SVG;
                            background-size: 16px;
                            background-position: center center;
                        }

                        .dropdownAcc {
                            float: left;
                            overflow: hidden;
                            margin-right: 10px;

                            .dropbtnAcc {
                                border: none;
                                outline: none;
                                color: $color-blue-darker;
                                font-family: $font-agaramondB;
                                font-size: $fontsize-21;
                                margin: 0px 0px 0px 0px;
                                background: transparent;
                            }

                            .dropbtnAcc:hover {
                                cursor: pointer;
                            }

                            .dropbtnAcc:hover .arrow_down {
                                border-left: 7px solid transparent;
                                border-right: 7px solid transparent;
                                border-bottom: 7px solid $color-offBlack;
                                border-top: none;
                            }

                            .dropdown-content {
                                display: none;
                                position: absolute;
                                width: 190px;
                                z-index: 999;

                                a {
                                    display: block;
                                    color: $color-blue-darker;
                                    font-family: $font-agaramondB;
                                    padding: 10px 30px 10px 20px;
                                    text-decoration: none;
                                    text-align: left;
                                    background: $color-white;
                                    border-left: 1px solid $color-lighterwhite;
                                    border-right: 1px solid $color-lighterwhite;
                                }

                                a:hover {
                                    color: $color-red;
                                    background-image: $bgTexure;
                                }

                                a:first-child {
                                    border-top-left-radius: 7px;
                                    border-top-right-radius: 7px;
                                    border-top: 1px solid $color-lighterwhite;
                                    padding-top: 22px;
                                }

                                a:last-child {
                                    border-bottom-left-radius: 10px;
                                    border-bottom-right-radius: 10px;
                                    border-bottom: 1px solid $color-lighterwhite;
                                    padding-bottom: 22px;
                                }
                            }

                            .dropdown-content a:hover .loginIcon {
                                background: $loginSignup_mo_Icon_SVG;
                                background-size: 18px;
                                background-position: center center;
                            }

                            .dropdown-content a:hover .profileInfoIcon {
                                background: $profileInfo_mo_Icon_SVG;
                                background-size: 18px;
                                background-position: center center;
                            }

                            .dropdown-content a:hover .supportIcon {
                                background: $support_mo_Icon_SVG;
                                background-size: 18px;
                                background-position: center center;
                            }

                            .dropdown-content a:hover .fgtpasswordIcon {
                                background: $fgtpswd_mo_Icon_SVG;
                                background-size: 16px;
                                background-position: center center;
                            }

                            .dropdown-content a:hover .logoutNewIcon {
                                background: $logout_offwhite_mo_Icon_SVG;
                                background-size: 16px;
                                background-position: center center;
                            }
                        }

                        @media ($smaller-than: $breakpoint-699) {
                            .dropdownAcc {
                                margin-right: 0px;
                            }

                            .dropdown-content {
                                right: 0px;
                            }
                        }

                        .dropdownAcc:hover .arrow_down {
                            border-left: 7px solid transparent;
                            border-right: 7px solid transparent;
                            border-bottom: 7px solid $color-offBlack;
                            border-top: none;
                        }

                        .dropbtnAcc.active:hover {
                            color: $color-white;
                        }

                        .dropdownAcc:hover .dropdown-content {
                            display: block;
                        }
                    }
                }
            }
        }

        .getStarted_btnLabel {
            display: inline-block;
            vertical-align: top;
            margin-left: 20px;

            .getStarted_button {
                padding: 12px 18px;
                font-family: $font-agaramondB;
                font-size: $fontsize-18;
                color: $color-white;
                text-transform: uppercase;
                border: 0px;
                background-image: linear-gradient(to bottom right, $color-red 50%, $color-orange-light);
                outline: none;
                border-radius: 5px;
                text-transform: capitalize;
                -webkit-transition: all .2s ease-in-out;
                -moz-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
            }

            .getStarted_button:hover {
                cursor: pointer;
                background-image: linear-gradient(to right, $color-red, $color-red);
            }
        }
    }

    // @media ($smaller-than: $breakpoint-1023) {
    //     .headerTopRight {
    //         display: none;
    //     }
    // }

    .navBlock {
        display: block;
        width: $width-1200px;
        margin: 0px auto;
        background: $color-blue-navi-bg;
        box-shadow: 0px 4px 5px rgba(255, 255, 255, .25);
        border-radius: 3px;
        padding: 10px 0px;
        -webkit-transition: transform .10s;
        -moz-transition: transform .10s;
        transition: transform .10s;

        .topnav {
            overflow: hidden;
            margin-left: 10px;

            .line_topnav {
                display: inline-block;
                vertical-align: middle;
                height: 15px;
                width: 18px;
                background: $menuIcon_SVG;
                background-size: 12px;
                background-position: top right;
                margin-top: -3px;
            }

            .arrow_down_topnav {
                display: inline-block;
                vertical-align: middle;
                margin: -5px 0 0 5px;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid $color-white;
                -webkit-transition: all .2s ease-in-out;
                -moz-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
            }

            .arrow_down_topnav.active {
                border-top: 6px solid $color-red;
            }

            .icon {
                display: none;
                height: 37px;
                width: 40px;
                background: $menuIcon_SVG;
                background-size: 20px;
                background-position: center center;
            }

            .icon:hover {
                background: $menuIcon_SVG;
                background-size: 20px;
                background-position: center center;
            }

            .dropdown {
                float: left;
                overflow: hidden;

                .dropbtn {
                    border: none;
                    outline: none;
                    color: $color-white;
                    padding: 13px 17px 11px 16px;
                    font-family: $font-agaramondB;
                    font-size: $fontsize-17;
                    margin: 0;
                    background: transparent;

                }

                @-moz-document url-prefix() {
                    .dropbtn {
                        padding: 12px 16px 11px 16px;
                    }
                }

                .dropbtn:hover {
                    cursor: pointer;
                }

                .dropbtn.active {
                    color: $color-red;
                    background: $color-white;
                    background-image: $bgTexure;
                }

                .dropbtn.active:hover {
                    background: $color-white;
                    background-image: $bgTexure;
                    color: $color-black;
                }

                .dropdown-content {
                    display: none;
                    position: absolute;
                    min-width: 160px;
                    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                    z-index: 998;

                    a {
                        display: block;
                        float: none;
                        color: $color-black;
                        font-family: $font-agaramondB;
                        padding: 8px 20px 8px 16px;
                        text-decoration: none;
                        text-align: left;
                        background: $bgTexure $color-white;
                        border-bottom: .5px solid $color-lighterwhite;
                    }

                    a:hover,
                    a:first-child:hover {
                        color: $color-red;
                        background: $color-white-light;
                        // background-image: $bgTexure;
                    }

                    a:last-child {
                        border-bottom: none;
                    }

                }
            }

            .dropdown:hover .arrow_down_topnav {
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 6px solid $color-red;
                border-top: none;
            }

            .dropdown:hover .line_topnav {
                background: none;
                margin: -5px 0 0 5px;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 6px solid $color-red;
            }

            .dropdown:hover .dropbtn.active {
                background: $color-white;
                background-image: $bgTexure;
                color: $color-black;
            }

            .dropdown:hover .dropbtn {
                background: $color-white-light;
                color: $color-black;
            }

            .dropbtn.active:hover {
                color: $color-white;
            }

            .dropdown:hover .dropdown-content {
                display: block;
            }
        }

        // @media screen and (max-width: $breakpoint-1023) {
        //     a {
        //         display: inline-block;
        //         vertical-align: middle;
        //     }

        //     .topnav a:not(:first-child),
        //     .dropdown .dropbtn {
        //         display: none;
        //     }

        //     .topnav a.icon {
        //         float: right;
        //         display: block;
        //     }

        //     .topnav.responsive {
        //         position: relative;
        //     }

        //     .topnav.responsive .icon {
        //         position: absolute;
        //         right: 0;
        //         top: 0;
        //     }

        //     .topnav.responsive a {
        //         display: block;
        //         float: none;
        //         text-align: left;
        //     }

        //     .topnav.responsive .dropdown {
        //         float: none;
        //     }

        //     .topnav.responsive .dropdown-content {
        //         position: relative;
        //     }

        //     .topnav.responsive .dropdown .dropbtn {
        //         display: block;
        //         width: 100%;
        //         text-align: left;
        //     }
        // }

        .headerRightBlock {
            float: right;
            margin-right: 20px;

            ul.supportNavi {
                display: block;

                li.supportNavi {
                    display: inline-block;
                    vertical-align: top;
                    margin: 5px 0px 0px 8px;
                    background: $color-blue-darkest;
                    border-radius: 5px;
                    padding: 3px 13px;
                    -webkit-transition: all .5s ease-in-out;
                    -moz-transition: all .5s ease-in-out;
                    transition: all .5s ease-in-out;
                }

                li.supportNavi:hover {
                    cursor: pointer;
                    background: $color-black;
                }

                li.supportNavi:first-child {
                    margin-left: 0px;
                }

                .supportNaviText {
                    display: inline-block;
                    vertical-align: top;
                    font-family: $font-agaramondB;
                    font-size: $fontsize-15;
                    color: $color-lighterwhite;
                    line-height: 28px;
                    -webkit-transition: all .3s ease-in-out;
                    -moz-transition: all .3s ease-in-out;
                    transition: all .3s ease-in-out;
                }

                .supportNaviText:hover {
                    color: $color-white;
                }

                .becomeBranchIcon,
                .findOfficerIcon {
                    display: inline-block;
                    vertical-align: top;
                    width: 15px;
                    height: 26px;
                    margin-right: 10px;
                }

                .becomeBranchIcon {
                    background: $joinIcon_SVG;
                    background-size: 15px;
                    background-position: left center;
                }

                .findOfficerIcon {
                    background: $findIcon_SVG;
                    background-size: 15px;
                    background-position: left center;
                }

                // @media ($smaller-than: $breakpoint-1200) {
                //     .supportNaviText {
                //         display: none;
                //     }

                //     .becomeBranchIcon,
                //     .findOfficerIcon {
                //         margin-right: 0px;
                //     }
                // }
            }
        }
    }

    // @media ($smaller-than: $breakpoint-1200) {
    //     .navBlock {
    //         width: 100%;
    //         margin: 0px 0px;
    //         border-radius: 0px;
    //     }
    // }
}

.headerMini {
    display: none;
    width: 100%;
    height: 64px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, .25);
    position: fixed;
    visibility: visible;
    left: 0px;
    top: 0px;
    z-index: 999;

    .navBlockMini {
        display: block;
        // background: $color-blue-navi-bg;
        background: $color-white;
        padding: 10px 0px;
        -webkit-transition: transform .10s;
        -moz-transition: transform .10s;
        transition: transform .10s;
        // box-shadow: 0px 4px 5px rgba(0, 0, 0, .25);

        .topnav {
            overflow: hidden;
            margin-left: 10px;

            .line_topnav {
                display: inline-block;
                vertical-align: middle;
                height: 15px;
                width: 18px;
                background: $menuIconBlue_SVG;
                background-size: 12px;
                background-position: top right;
                margin-top: -3px;
            }

            .arrow_down_topnav {
                display: inline-block;
                vertical-align: middle;
                margin: -5px 0 0 5px;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid $color-blue-navi-bg;
                -webkit-transition: all .2s ease-in-out;
                -moz-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
            }

            a {
                display: block;
                float: left;
                color: $color-white;
                text-align: center;
                padding: 5px 8px 0px 8px;
                text-decoration: none;
                font-family: $font-arial;
                font-size: $fontsize-14;
                background: $color-white;
                // background-image: $bgTexure;
            }

            @-moz-document url-prefix() {
                a {
                    padding: 7px 16px 7.3px 16px;
                }
            }

            .logoPosMini {
                height: 38px;
                width: 65px;
            }

            .icon {
                display: none;
                height: 40px;
                width: 40px;
                background: $menuIconBlue_SVG;
                background-size: 20px;
                background-position: center center;
                border-left: 1px solid $color-lighterwhite;
            }

            .icon:hover {
                background: $menuIconBlue_SVG;
                background-size: 20px;
                background-position: center center;
            }

            .dropdown {
                float: left;
                overflow: hidden;

                .dropbtn {
                    border: none;
                    outline: none;
                    color: $color-blue-navi-bg;
                    padding: 13px 17px 11px 16px;
                    font-family: $font-agaramondB;
                    font-size: $fontsize-18;
                    margin: 0;
                    background: transparent;
                }

                @-moz-document url-prefix() {
                    .dropbtn {
                        padding: 12px 16px 11px 16px;
                    }
                }

                .dropbtn:hover {
                    cursor: pointer;
                }

                .dropbtn.active {
                    color: $color-red;
                    background: $color-white;
                    background-image: $bgTexure;
                }

                .dropbtn.active:hover {
                    background: $color-white;
                    background-image: $bgTexure;
                    color: $color-black;
                }

                .dropdown-content {
                    display: none;
                    position: absolute;
                    min-width: 160px;
                    // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                    z-index: 998;

                    a {
                        display: block;
                        float: none;
                        color: $color-black;
                        font-family: $font-agaramondB;
                        padding: 8px 20px 8px 16px;
                        text-decoration: none;
                        text-align: left;
                        background: $bgTexure $color-white;
                        border-bottom: .5px solid $color-lighterwhite;
                    }

                    a:hover,
                    a:first-child:hover {
                        color: $color-red;
                        background: $color-white-light;
                        // background-image: $bgTexure;
                    }

                    a:last-child {
                        border-bottom: none;
                    }

                }
            }

            .dropdown:hover .arrow_down_topnav {
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 6px solid $color-red;
                border-top: none;
            }

            .dropdown:hover .line_topnav {
                background: none;
                margin: -5px 0 0 5px;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 6px solid $color-red;
            }

            .dropdown:hover .dropbtn.active {
                background: $color-white;
                background-image: $bgTexure;
                color: $color-black;
            }

            .dropdown:hover .dropbtn {
                background: $color-white-light;
                color: $color-black;
            }

            .dropbtn.active:hover {
                color: $color-white;
            }

            .dropdown:hover .dropdown-content {
                display: block;
            }
        }

        @media screen and (max-width: $breakpoint-1023) {

            a {
                display: inline-block;
                vertical-align: middle;
            }

            .topnav a:not(:first-child),
            .dropdown .dropbtn {
                display: none;
            }

            .topnav a.icon {
                float: right;
                display: block;
            }

            .topnav.responsive {
                position: relative;
                box-shadow: 0px 4px 5px rgba(0, 0, 0, .25);
                margin-left: 0px;
                padding-left: 10px;
            }

            .topnav.responsive .icon {
                position: absolute;
                height: 40px;
                width: 40px;
                right: 0;
                top: 0;
                background: $menuCrossRed_SVG;
                background-size: 16px;
                background-position: center center;
                border-left: 1px solid $color-lighterwhite;
            }

            .topnav.responsive a {
                display: block;
                float: none;
                text-align: left;
            }

            .topnav.responsive .dropdown {
                float: none;
            }

            .topnav.responsive .dropdown-content {
                position: relative;

                a {
                    color: $color-blacklight;
                }

                a:last-child {
                    border-bottom: .5px solid $color-lighterwhite;
                }

            }

            .topnav.responsive .dropdown .dropbtn {
                display: block;
                width: 100%;
                text-align: left;
                font-size: $fontsize-16;
                color: $color-black;
                padding-left: 10px;
            }

            .dropdown:hover .dropbtn {
                border-top: 1px solid $color-lighterwhite;
            }
        }
    }

    .headerTopRightMini {
        float: right;
        margin-top: 10px;

        ul.header_Account_blk_mini {
            display: inline-block;
            vertical-align: top;
            margin: 0px;
            padding: 5px 0 3px 0px;

            li.header_profile_img_mini {
                display: inline-block;
                vertical-align: top;
                height: 40px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                border: 1px solid $color-red-light;
                background: $profileDefaultIcon_SVG;
                background-position: center bottom;
                background-size: 27px;
                margin-top: -5px;
            }

            // @media ($smaller-than: $breakpoint-599) {
            //     li.header_profile_img {
            //         display: none;
            //     }
            // }

            li.header_nameAcc_blk_mini {
                display: inline-block;
                vertical-align: top;
                margin-left: 10px;

                .headerProfileName_mini {
                    display: block;
                    width: 100px;
                    font-family: $font-agaramond;
                    font-size: $fontsize-13;
                    color: $color-red;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .headerAccount_mini {
                    display: block;

                    .accountnav_mini {
                        overflow: hidden;

                        .arrow_down_mini {
                            display: inline-block;
                            vertical-align: top;
                            margin-top: 9px;
                            margin-left: 7px;
                            width: 0;
                            height: 0;
                            border-left: 7px solid transparent;
                            border-right: 7px solid transparent;
                            border-top: 7px solid $color-blue-darker;
                        }

                        .loginIcon,
                        .profileInfoIcon,
                        .supportIcon,
                        .fgtpasswordIcon,
                        .logoutNewIcon {
                            display: inline-block;
                            vertical-align: top;
                            height: 20px;
                            width: 20px;
                            margin-right: 9px;
                        }

                        .loginIcon {
                            background: $loginSignupIcon_SVG;
                            background-size: 18px;
                            background-position: center center;
                        }

                        .profileInfoIcon {
                            background: $profileInfoIcon_SVG;
                            background-size: 18px;
                            background-position: center center;
                        }

                        .supportIcon {
                            background: $supportIcon_SVG;
                            background-size: 18px;
                            background-position: center center;
                        }

                        .fgtpasswordIcon {
                            background: $fgtpswdIcon_SVG;
                            background-size: 16px;
                            background-position: center center;
                        }

                        .logoutNewIcon {
                            background: $logout_offwhite_Icon_SVG;
                            background-size: 16px;
                            background-position: center center;
                        }

                        .dropdownAcc_mini {
                            float: left;
                            overflow: hidden;
                            // margin-right: 10px;

                            .dropbtnAcc_mini {
                                border: none;
                                outline: none;
                                color: $color-blue-darker;
                                font-family: $font-agaramondB;
                                font-size: $fontsize-21;
                                margin: 0px;
                                background: transparent;
                            }

                            .dropbtnAcc_mini:hover {
                                cursor: pointer;
                            }

                            .dropbtnAcc_mini:hover .arrow_down_mini {
                                border-left: 7px solid transparent;
                                border-right: 7px solid transparent;
                                border-bottom: 7px solid $color-red;
                                border-top: none;
                            }

                            .dropdown-content_mini {
                                display: none;
                                position: absolute;
                                min-width: 190px;
                                z-index: 999;

                                a {
                                    display: block;
                                    color: $color-blue-darker;
                                    font-family: $font-agaramondB;
                                    padding: 10px 30px 10px 20px;
                                    text-decoration: none;
                                    text-align: left;
                                    background: $color-white;
                                    border-left: 1px solid $color-lighterwhite;
                                    border-right: 1px solid $color-lighterwhite;
                                }

                                a:hover {
                                    color: $color-red;
                                    background-image: $bgTexure;
                                }

                                a:first-child {
                                    border-top-left-radius: 7px;
                                    border-top-right-radius: 7px;
                                    border-top: 1px solid $color-lighterwhite;
                                    padding-top: 22px;
                                }

                                a:last-child {
                                    border-bottom-left-radius: 10px;
                                    border-bottom-right-radius: 10px;
                                    border-bottom: 1px solid $color-lighterwhite;
                                    padding-bottom: 22px;
                                }
                            }

                            .dropdown-content_mini a:hover .loginIcon {
                                background: $loginSignup_mo_Icon_SVG;
                                background-size: 18px;
                                background-position: center center;
                            }

                            .dropdown-content_mini a:hover .profileInfoIcon {
                                background: $profileInfo_mo_Icon_SVG;
                                background-size: 18px;
                                background-position: center center;
                            }

                            .dropdown-content_mini a:hover .supportIcon {
                                background: $support_mo_Icon_SVG;
                                background-size: 18px;
                                background-position: center center;
                            }

                            .dropdown-content_mini a:hover .fgtpasswordIcon {
                                background: $fgtpswd_mo_Icon_SVG;
                                background-size: 16px;
                                background-position: center center;
                            }

                            .dropdown-content_mini a:hover .logoutNewIcon {
                                background: $logout_offwhite_mo_Icon_SVG;
                                background-size: 16px;
                                background-position: center center;
                            }
                        }

                        @media ($smaller-than: $breakpoint-1200) {
                            .dropdownAcc_mini {
                                margin-right: 0px;
                            }

                            .dropdown-content_mini {
                                right: -30px;
                            }
                        }

                        .dropdownAcc_mini:hover .arrow_down_mini {
                            border-left: 7px solid transparent;
                            border-right: 7px solid transparent;
                            border-bottom: 7px solid $color-red;
                            border-top: none;
                        }

                        .dropbtnAcc_mini.active:hover {
                            color: $color-white;
                        }

                        .dropdownAcc_mini:hover .dropdown-content_mini {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    @media ($smaller-than: $breakpoint-1200) {
        .headerTopRightMini {
            position: absolute;
            right: 50px;
            top: 0px;
            z-index: 120;
        }
    }
}

@media ($smaller-than: $breakpoint-1200) {
    .headerSiddhi {
        display: none;
    }

    .headerMini {
        display: block;
    }
}


//App Header
// .header,
// .headerResponsive,
// .headerInpage {
//     display: block;
//     width: 100%;
//     visibility: visible;
//     left: 0px;
//     top: 0px;
//     position: absolute;
//     z-index: 25;
//     background: $color-white;

//     .logo {
//         display: inline-block;
//         vertical-align: top;

//         .logoPositionHome {
//             height: 58px;
//             width: 70px;
//             margin: 10px 0px 0px 15px;
//             background-position: left top;
//             background-size: 230px;
//         }
//     }

//     .topRight_fixed_blk {
//         position: absolute;
//         width: 1px;
//         margin: 0px auto;
//         z-index: 21;
//         top: 0px;
//         float: right;
//     }

//     .headerTopRight {
//         float: right;
//         margin-top: 3px;

//         .header_Ph_blk {
//             display: inline-block;
//             vertical-align: top;
//             border-left: 1px solid $color-whitebluedark;
//             margin: 7px 2px 0px 4px;
//             padding: 5px 0px 3px 15px;

//             .callus {
//                 display: block;
//                 font-family: $font-agaramondB;
//                 font-size: $fontsize-12;
//                 color: $color-red;
//                 margin-bottom: 4px;
//             }

//             .phNo {
//                 display: block;
//                 font-family: $font-agaramondB;
//                 font-size: $fontsize-20;
//                 color: $color-blackblue;
//             }
//         }

//         ul.header_Account_blk {
//             display: inline-block;
//             vertical-align: top;
//             margin: 7px 0px 0px 0px;
//             padding: 5px 0 3px 20px;

//             li.header_profile_img {
//                 display: inline-block;
//                 vertical-align: top;
//                 height: 43px;
//                 aspect-ratio: 1/1;
//                 border-radius: 50%;
//                 border: 1px solid $color-red-light;
//                 background: $profileDefaultIcon_SVG $color-pink-light;
//                 background-position: center bottom;
//                 background-size: 28px;
//                 margin-top: -3px;
//             }

//             li.header_nameAcc_blk {
//                 display: inline-block;
//                 vertical-align: top;
//                 margin-left: 10px;

//                 .headerProfileName {
//                     display: block;
//                     width: 100px;
//                     font-family: $font-agaramondB;
//                     font-size: $fontsize-13;
//                     color: $color-red;
//                     white-space: nowrap;
//                     overflow: hidden;
//                     text-overflow: ellipsis;
//                 }

//                 .headerAccount {
//                     display: block;

//                     .accountnav {
//                         overflow: hidden;

//                         .arrow_down {
//                             display: inline-block;
//                             vertical-align: top;
//                             margin-top: 9px;
//                             margin-left: 7px;
//                             width: 0;
//                             height: 0;
//                             border-left: 7px solid transparent;
//                             border-right: 7px solid transparent;
//                             border-top: 7px solid $color-blue-darker;
//                         }

//                         .loginIcon,
//                         .profileInfoIcon,
//                         .supportIcon,
//                         .fgtpasswordIcon,
//                         .logoutNewIcon,
//                         // .consentIcon,
//                         .subscriptionIcon {
//                             display: inline-block;
//                             vertical-align: top;
//                             height: 20px;
//                             width: 20px;
//                             margin-right: 9px;
//                         }

//                         .loginIcon {
//                             background: $loginSignupIcon_SVG;
//                             background-size: 18px;
//                             background-position: center center;
//                         }

//                         .profileInfoIcon {
//                             background: $profileInfoIcon_SVG;
//                             background-size: 18px;
//                             background-position: center center;
//                         }

//                         .supportIcon {
//                             background: $supportIcon_SVG;
//                             background-size: 18px;
//                             background-position: center center;
//                         }

//                         .fgtpasswordIcon {
//                             background: $fgtpswdIcon_SVG;
//                             background-size: 16px;
//                             background-position: center center;
//                         }

//                         .logoutNewIcon {
//                             background: $logout_offwhite_Icon_SVG;
//                             background-size: 16px;
//                             background-position: center center;
//                         }

//                         // .consentIcon {
//                         //     background: $consent_Icon_SVG;
//                         //     background-size: 16px;
//                         //     background-position: center center;
//                         // }

//                         .subscriptionIcon {
//                             background: $subscription_Icon_SVG;
//                             background-size: 16px;
//                             background-position: center center;
//                         }

//                         .dropdownAcc {
//                             float: left;
//                             overflow: hidden;
//                             margin-right: 10px;

//                             .dropbtnAcc {
//                                 border: none;
//                                 outline: none;
//                                 color: $color-blue-darker;
//                                 font-family: $font-agaramondB;
//                                 font-size: $fontsize-21;
//                                 margin: 0px 0px 0px 0px;
//                                 background: transparent;
//                             }

//                             .dropbtnAcc:hover {
//                                 cursor: pointer;
//                             }

//                             .dropbtnAcc:hover .arrow_down {
//                                 border-left: 7px solid transparent;
//                                 border-right: 7px solid transparent;
//                                 border-bottom: 7px solid $color-red;
//                                 border-top: none;
//                             }

//                             .dropdown-content {
//                                 display: none;
//                                 position: absolute;
//                                 width: 210px;
//                                 z-index: 999;

//                                 a {
//                                     display: block;
//                                     color: $color-blue-darker;
//                                     font-family: $font-agaramondB;
//                                     padding: 10px 30px 10px 20px;
//                                     text-decoration: none;
//                                     text-align: left;
//                                     background: $color-white;
//                                     border-left: 1px solid $color-lighterwhite;
//                                     border-right: 1px solid $color-lighterwhite;
//                                 }

//                                 a:hover {
//                                     color: $color-red;
//                                     background-image: $bgTexure;
//                                 }

//                                 a:first-child {
//                                     border-top-left-radius: 7px;
//                                     border-top-right-radius: 7px;
//                                     border-top: 1px solid $color-lighterwhite;
//                                     padding-top: 22px;
//                                 }

//                                 a:last-child {
//                                     border-bottom-left-radius: 10px;
//                                     border-bottom-right-radius: 10px;
//                                     border-bottom: 1px solid $color-lighterwhite;
//                                     padding-bottom: 22px;
//                                 }
//                             }

//                             .dropdown-content a:hover .loginIcon {
//                                 background: $loginSignup_mo_Icon_SVG;
//                                 background-size: 18px;
//                                 background-position: center center;
//                             }

//                             .dropdown-content a:hover .profileInfoIcon {
//                                 background: $profileInfo_mo_Icon_SVG;
//                                 background-size: 18px;
//                                 background-position: center center;
//                             }

//                             .dropdown-content a:hover .supportIcon {
//                                 background: $support_mo_Icon_SVG;
//                                 background-size: 18px;
//                                 background-position: center center;
//                             }

//                             .dropdown-content a:hover .fgtpasswordIcon {
//                                 background: $fgtpswd_mo_Icon_SVG;
//                                 background-size: 16px;
//                                 background-position: center center;
//                             }

//                             .dropdown-content a:hover .logoutNewIcon {
//                                 background: $logout_offwhite_mo_Icon_SVG;
//                                 background-size: 16px;
//                                 background-position: center center;
//                             }

//                             // .dropdown-content a:hover .consentIcon {
//                             //     background: $consent_mo_Icon_SVG;
//                             //     background-size: 16px;
//                             //     background-position: center center;
//                             // }

//                             .dropdown-content a:hover .subscriptionIcon {
//                                 background: $subscription_mo_Icon_SVG;
//                                 background-size: 16px;
//                                 background-position: center center;
//                             }
//                         }

//                         @media ($smaller-than: $breakpoint-699) {
//                             .dropdownAcc {
//                                 margin-right: 0px;
//                             }

//                             .dropdown-content {
//                                 right: 0px;
//                             }
//                         }

//                         .dropdownAcc:hover .arrow_down {
//                             border-left: 7px solid transparent;
//                             border-right: 7px solid transparent;
//                             border-bottom: 7px solid $color-red;
//                             border-top: none;
//                         }

//                         .dropbtnAcc.active:hover {
//                             color: $color-white;
//                         }

//                         .dropdownAcc:hover .dropdown-content {
//                             display: block;
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     // .navBlock,
//     // .navBlockInpage {
//     //     display: block;
//     //     border-top: 1px solid $color-whitebluedark;
//     //     margin: 0px auto;
//     //     background: $color-white;
//     //     box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
//     //     -webkit-transition: transform 0.1s;
//     //     -moz-transition: transform 0.1s;
//     //     transition: transform 0.1s;

//     //     .topnav {
//     //         overflow: hidden;

//     //         .dashboardIcon,
//     //         .documentIcon,
//     //         .preapproveIcon,
//     //         .formIcon {
//     //             display: inline-block;
//     //             vertical-align: middle;
//     //             width: 18px;
//     //             aspect-ratio: 1/1;
//     //             margin: -5px 5px 0px 0px;
//     //         }

//     //         .dashboardIcon {
//     //             // background: $dashboardIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .documentIcon {
//     //             // background: $documentIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .preapproveIcon {
//     //             // background: $preapproveIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .formIcon {
//     //             // background: $applicationIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .dashboardIcon.active {
//     //             // background: $dashboardRedIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .documentIcon.active {
//     //             // background: $documentBlackIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .preapproveIcon.active {
//     //             // background: $preapproveBlackIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .formIcon.active {
//     //             // background: $applicationBlackIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .line_topnav {
//     //             display: inline-block;
//     //             vertical-align: middle;
//     //             height: 15px;
//     //             width: 18px;
//     //             background: $menuIcon_SVG;
//     //             background-size: 12px;
//     //             background-position: top right;
//     //             margin-top: -3px;
//     //         }

//     //         .arrow_down_topnav {
//     //             display: inline-block;
//     //             vertical-align: middle;
//     //             margin: -5px 0 0 5px;
//     //             width: 0;
//     //             height: 0;
//     //             border-left: 6px solid transparent;
//     //             border-right: 6px solid transparent;
//     //             border-top: 6px solid $color-blackbrown-light;
//     //             -webkit-transition: all 0.2s ease-in-out;
//     //             -moz-transition: all 0.2s ease-in-out;
//     //             transition: all 0.2s ease-in-out;
//     //         }

//     //         .icon {
//     //             display: none;
//     //             height: 37px;
//     //             width: 40px;
//     //             background: $menuIcon_SVG;
//     //             background-size: 20px;
//     //             background-position: center center;
//     //         }

//     //         .icon:hover {
//     //             background: $menuIcon_SVG;
//     //             background-size: 20px;
//     //             background-position: center center;
//     //         }

//     //         .dropdown {
//     //             float: left;
//     //             overflow: hidden;

//     //             .dropbtn {
//     //                 border: none;
//     //                 outline: none;
//     //                 color: $color-blue-darker;
//     //                 padding: 13px 17px 11px 16px;
//     //                 font-family: $font-agaramondB;
//     //                 font-size: $fontsize-17;
//     //                 margin: 0;
//     //                 background: transparent;
//     //                 -webkit-transition: all 0.5s ease-in-out;
//     //                 -moz-transition: all 0.5s ease-in-out;
//     //                 transition: all 0.5s ease-in-out;
//     //             }

//     //             @-moz-document url-prefix() {
//     //                 .dropbtn {
//     //                     padding: 12px 16px 11px 16px;
//     //                 }
//     //             }

//     //             .dropbtn:hover {
//     //                 cursor: pointer;
//     //             }

//     //             .dropbtn:hover .dashboardIcon {
//     //                 background: $dashboardBlackIcon_SVG;
//     //                 background-size: 16px;
//     //                 background-position: top left;
//     //                 -webkit-transition: all 0.5s ease-in-out;
//     //                 -moz-transition: all 0.5s ease-in-out;
//     //                 transition: all 0.5s ease-in-out;
//     //             }

//     //             .dropbtn:hover .documentIcon {
//     //                 background: $documentBlackIcon_SVG;
//     //                 background-size: 16px;
//     //                 background-position: top left;
//     //                 -webkit-transition: all 0.5s ease-in-out;
//     //                 -moz-transition: all 0.5s ease-in-out;
//     //                 transition: all 0.5s ease-in-out;
//     //             }

//     //             .dropbtn:hover .preapproveIcon {
//     //                 background: $preapproveBlackIcon_SVG;
//     //                 background-size: 16px;
//     //                 background-position: top left;
//     //                 -webkit-transition: all 0.5s ease-in-out;
//     //                 -moz-transition: all 0.5s ease-in-out;
//     //                 transition: all 0.5s ease-in-out;
//     //             }

//     //             .dropbtn:hover .formIcon {
//     //                 background: $applicationBlackIcon_SVG;
//     //                 background-size: 16px;
//     //                 background-position: top left;
//     //                 -webkit-transition: all 0.5s ease-in-out;
//     //                 -moz-transition: all 0.5s ease-in-out;
//     //                 transition: all 0.5s ease-in-out;
//     //             }

//     //             .dropbtn.active {
//     //                 color: $color-red-darker;
//     //                 background: $color-offwhitelight;
//     //                 background-image: $bgTexure;
//     //             }

//     //             .dropbtn.active:hover {
//     //                 background: $color-offwhitelight;
//     //                 background-image: $bgTexure;
//     //                 color: $color-blackblue;
//     //             }

//     //             .dropbtn.active:hover .dashboardIcon {
//     //                 background: $dashboardBlackIcon_SVG;
//     //                 background-size: 16px;
//     //                 background-position: top left;
//     //                 -webkit-transition: all 0.5s ease-in-out;
//     //                 -moz-transition: all 0.5s ease-in-out;
//     //                 transition: all 0.5s ease-in-out;
//     //             }

//     //             .dropbtn.active:hover .documentIcon {
//     //                 background: $documentBlackIcon_SVG;
//     //                 background-size: 16px;
//     //                 background-position: top left;
//     //                 -webkit-transition: all 0.5s ease-in-out;
//     //                 -moz-transition: all 0.5s ease-in-out;
//     //                 transition: all 0.5s ease-in-out;
//     //             }

//     //             .dropbtn.active:hover .preapproveIcon {
//     //                 background: $preapproveBlackIcon_SVG;
//     //                 background-size: 16px;
//     //                 background-position: top left;
//     //                 -webkit-transition: all 0.5s ease-in-out;
//     //                 -moz-transition: all 0.5s ease-in-out;
//     //                 transition: all 0.5s ease-in-out;
//     //             }

//     //             .dropbtn.active:hover .formIcon {
//     //                 background: $applicationBlackIcon_SVG;
//     //                 background-size: 16px;
//     //                 background-position: top left;
//     //                 -webkit-transition: all 0.5s ease-in-out;
//     //                 -moz-transition: all 0.5s ease-in-out;
//     //                 transition: all 0.5s ease-in-out;
//     //             }

//     //             // .dropbtn.active:after {
//     //             //     content: ' ';
//     //             //     height: 0;
//     //             //     position: absolute;
//     //             //     width: 0;
//     //             //     border: 7px solid transparent;
//     //             //     border-top-color: green;
//     //             //     top: 19px;
//     //             //     left: 50%;
//     //             //     margin-left: -10px;
//     //             // }

//     //             //.dropbtn.active:hover .documentIcon {
//     //             //  background: $documentBlueIcon_SVG;
//     //             //}
//     //             //.dropbtn.active:hover .loanIcon {
//     //             //  background: $loanBlueIcon_SVG;
//     //             //}
//     //             .dropdown-content {
//     //                 display: none;
//     //                 position: absolute;
//     //                 //background: $color-white;
//     //                 min-width: 150px;
//     //                 box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
//     //                 z-index: 998;

//     //                 a {
//     //                     display: block;
//     //                     float: none;
//     //                     color: $color-blackbrown;
//     //                     font-family: $font-agaramondB;
//     //                     padding: 8px 20px 8px 16px;
//     //                     text-decoration: none;
//     //                     text-align: left;
//     //                     background: $color-white;
//     //                     border-bottom: 1px solid $color-whitered;
//     //                 }

//     //                 a:hover

//     //                 /*,
//     //     		a:first-child:hover*/
//     //                     {
//     //                     color: $color-red;
//     //                     background-image: $bgTexure;
//     //                 }

//     //                 a:last-child {
//     //                     border-bottom: none;
//     //                 }

//     //                 /*a:first-child {
//     //       		  padding: 10px 16px;
//     //       		  background: transparent;
//     //     		  color: $color-black;
//     //     		}*/
//     //             }
//     //         }

//     //         .dropdown:hover .dropbtn.active .dashboardIcon {
//     //             background: $dashboardBlackIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .dropdown:hover .dropbtn .dashboardIcon {
//     //             background: $dashboardBlackIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .dropdown:hover .dropbtn.active .documentIcon {
//     //             background: $documentBlackIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .dropdown:hover .dropbtn .documentIcon {
//     //             background: $documentBlackIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .dropdown:hover .dropbtn.active .preapproveIcon {
//     //             background: $preapproveBlackIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .dropdown:hover .dropbtn .preapproveIcon {
//     //             background: $preapproveBlackIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .dropdown:hover .dropbtn.active .formIcon {
//     //             background: $applicationBlackIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .dropdown:hover .dropbtn .formIcon {
//     //             background: $applicationBlackIcon_SVG;
//     //             background-size: 16px;
//     //             background-position: top left;
//     //         }

//     //         .dropdown:hover .arrow_down_topnav {
//     //             border-left: 6px solid transparent;
//     //             border-right: 6px solid transparent;
//     //             border-bottom: 6px solid $color-red;
//     //             border-top: none;
//     //         }

//     //         .dropdown:hover .line_topnav {
//     //             background: none;
//     //             margin: -5px 0 0 5px;
//     //             width: 0;
//     //             height: 0;
//     //             border-left: 7px solid transparent;
//     //             border-right: 7px solid transparent;
//     //             border-bottom: 7px solid $color-red;
//     //             // border-top: none;
//     //         }

//     //         .dropdown:hover .dropbtn.active {
//     //             background: $color-offwhitelight;
//     //             background-image: $bgTexure;
//     //             color: $color-black;
//     //         }

//     //         .dropdown:hover .dropbtn {
//     //             // background: $color-whitered;
//     //             background: $color-offwhitelight;
//     //             background-image: $bgTexure;
//     //             color: $color-black;
//     //         }

//     //         .dropbtn.active:hover {
//     //             color: $color-white;
//     //         }

//     //         .dropdown:hover .dropdown-content {
//     //             display: block;
//     //         }
//     //     }

//     //     // @media screen and (max-width: $breakpoint-1023) {
//     //     //     a {
//     //     //         display: inline-block;
//     //     //         vertical-align: middle;
//     //     //     }

//     //     //     .topnav a:not(:first-child),
//     //     //     .dropdown .dropbtn {
//     //     //         display: none;
//     //     //     }

//     //     //     .topnav a.icon {
//     //     //         float: right;
//     //     //         display: block;
//     //     //     }

//     //     //     .topnav.responsive {
//     //     //         position: relative;
//     //     //     }

//     //     //     .topnav.responsive .icon {
//     //     //         position: absolute;
//     //     //         right: 0;
//     //     //         top: 0;
//     //     //     }

//     //     //     .topnav.responsive a {
//     //     //         display: block;
//     //     //         float: none;
//     //     //         text-align: left;
//     //     //     }

//     //     //     .topnav.responsive .dropdown {
//     //     //         float: none;
//     //     //     }

//     //     //     .topnav.responsive .dropdown-content {
//     //     //         position: relative;
//     //     //     }

//     //     //     .topnav.responsive .dropdown .dropbtn {
//     //     //         display: block;
//     //     //         width: 100%;
//     //     //         text-align: left;
//     //     //     }
//     //     // }

//     //     .headerRightBlock {
//     //         float: right;
//     //         vertical-align: top;
//     //         height: 37px;

//     //         ul.supportNavi {
//     //             display: block;

//     //             li.supportNavi {
//     //                 display: inline-block;
//     //                 vertical-align: top;
//     //                 margin-left: 8px;
//     //                 // background: $color-red-darker;
//     //                 background: $color-offwhitelight;
//     //                 border: 0.5px solid $color-offwhite;
//     //                 border-radius: 5px;
//     //                 padding: 0 11px;
//     //                 height: 26px;
//     //                 margin-top: 8px;
//     //                 -webkit-transition: all 0.5s ease-in-out;
//     //                 -moz-transition: all 0.5s ease-in-out;
//     //                 transition: all 0.5s ease-in-out;
//     //             }

//     //             li.supportNavi:hover {
//     //                 background-image: $bgTexure;
//     //             }

//     //             li.supportNavi:first-child {
//     //                 margin-left: 0px;
//     //             }

//     //             li.supportNavi a {
//     //                 text-decoration: none;
//     //                 color: $color-blackblue;
//     //                 // color: $color-white;
//     //             }

//     //             li.supportNavi a:hover {
//     //                 color: $color-red;
//     //                 // opacity: .85;
//     //             }

//     //             .supportNaviText {
//     //                 display: inline-block;
//     //                 vertical-align: top;
//     //                 font-family: $font-agaramondB;
//     //                 font-size: $fontsize-15;
//     //                 color: $color-blackbrown;
//     //                 line-height: 28px;
//     //                 -webkit-transition: all 0.3s ease-in-out;
//     //                 -moz-transition: all 0.3s ease-in-out;
//     //                 transition: all 0.3s ease-in-out;
//     //             }

//     //             .supportNaviText:hover {
//     //                 color: $color-red;
//     //             }

//     //             .becomeBranchIcon,
//     //             .findOfficerIcon {
//     //                 display: inline-block;
//     //                 vertical-align: top;
//     //                 width: 15px;
//     //                 height: 26px;
//     //                 margin-right: 10px;
//     //             }

//     //             .becomeBranchIcon {
//     //                 background: $joinIcon_SVG;
//     //                 background-size: 15px;
//     //                 background-position: left center;
//     //             }

//     //             .findOfficerIcon {
//     //                 background: $findIcon_SVG;
//     //                 background-size: 15px;
//     //                 background-position: left center;
//     //             }

//     //             @media ($smaller-than: $breakpoint-599) {
//     //                 .supportNaviText {
//     //                     display: none;
//     //                 }

//     //                 .becomeBranchIcon,
//     //                 .findOfficerIcon {
//     //                     margin-right: 0px;
//     //                     //background-position: center center;
//     //                 }
//     //             }
//     //         }
//     //     }
//     // }

//     // .navBlockInpage {
//     //     .topnav {
//     //         .dropdown {
//     //             .dropbtn {
//     //                 // font-family: $font-arial;
//     //                 // font-weight: bold;
//     //                 font-size: $fontsize-18;
//     //             }
//     //         }
//     //     }
//     // }
// }

// .headerResponsive,
// .headerInpage {
//     display: none;
//     position: fixed;

//     .navBlockResponsive {
//         padding: 7px 0px;
//         box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);

//         .topnav {
//             overflow: hidden;
//             margin-left: 0px;

//             .dashboardIcon,
//             .documentIcon,
//             .preapproveIcon,
//             .formIcon {
//                 display: inline-block;
//                 vertical-align: middle;
//                 width: 18px;
//                 aspect-ratio: 1/1;
//                 margin: -5px 5px 0px 0px;
//             }

//             .dashboardIcon {
//                 background: $dashboardIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .documentIcon {
//                 background: $documentIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .preapproveIcon {
//                 background: $preapproveIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .formIcon {
//                 background: $applicationIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .dashboardIcon.active {
//                 background: $dashboardRedIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .documentIcon.active {
//                 background: $documentBlackIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .preapproveIcon.active {
//                 background: $preapproveBlackIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .formIcon.active {
//                 background: $applicationBlackIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .line_topnav {
//                 display: inline-block;
//                 vertical-align: middle;
//                 height: 15px;
//                 width: 18px;
//                 background: $menuIcon_SVG;
//                 background-size: 12px;
//                 background-position: top right;
//                 margin-top: -3px;
//             }

//             .arrow_down_topnav {
//                 display: inline-block;
//                 vertical-align: middle;
//                 margin: -5px 0 0 5px;
//                 width: 0;
//                 height: 0;
//                 border-left: 6px solid transparent;
//                 border-right: 6px solid transparent;
//                 border-top: 6px solid $color-blackbrown-light;
//                 -webkit-transition: all 0.2s ease-in-out;
//                 -moz-transition: all 0.2s ease-in-out;
//                 transition: all 0.2s ease-in-out;
//             }

//             a {
//                 display: block;
//                 float: left;
//                 color: $color-white;
//                 text-align: center;
//                 padding: 5px 8px 0px 8px;
//                 text-decoration: none;
//                 font-family: $font-arial;
//                 font-size: $fontsize-14;
//                 background: $color-white;
//                 // background-image: $bgTexure;
//             }

//             @-moz-document url-prefix() {
//                 a {
//                     padding: 7px 16px 7.3px 16px;
//                 }
//             }

//             .logoPosResponsive {
//                 height: 42px;
//                 width: 75px;
//             }

//             .icon {
//                 display: none;
//                 height: 40px;
//                 width: 40px;
//                 background: $menuIcon_SVG;
//                 background-size: 22px;
//                 background-position: center center;
//                 border-left: 1px solid $color-lighterwhite;
//                 margin-top: 5px;
//             }

//             .icon:hover {
//                 background: $menuIconRed_SVG;
//                 background-size: 22px;
//                 background-position: center center;
//             }

//             .dropdown {
//                 float: left;
//                 overflow: hidden;

//                 .dropbtn {
//                     border: none;
//                     outline: none;
//                     color: $color-blue-darker;
//                     padding: 13px 17px 11px 16px;
//                     font-family: $font-agaramondB;
//                     font-size: $fontsize-17;
//                     margin: 0;
//                     background: transparent;
//                     -webkit-transition: all 0.5s ease-in-out;
//                     -moz-transition: all 0.5s ease-in-out;
//                     transition: all 0.5s ease-in-out;
//                 }

//                 @-moz-document url-prefix() {
//                     .dropbtn {
//                         padding: 12px 16px 11px 16px;
//                     }
//                 }

//                 .dropbtn:hover {
//                     cursor: pointer;
//                 }

//                 .dropbtn:hover .dashboardIcon {
//                     background: $dashboardBlackIcon_SVG;
//                     background-size: 16px;
//                     background-position: top left;
//                     -webkit-transition: all 0.5s ease-in-out;
//                     -moz-transition: all 0.5s ease-in-out;
//                     transition: all 0.5s ease-in-out;
//                 }

//                 .dropbtn:hover .documentIcon {
//                     background: $documentBlackIcon_SVG;
//                     background-size: 16px;
//                     background-position: top left;
//                     -webkit-transition: all 0.5s ease-in-out;
//                     -moz-transition: all 0.5s ease-in-out;
//                     transition: all 0.5s ease-in-out;
//                 }

//                 .dropbtn:hover .preapproveIcon {
//                     background: $preapproveBlackIcon_SVG;
//                     background-size: 16px;
//                     background-position: top left;
//                     -webkit-transition: all 0.5s ease-in-out;
//                     -moz-transition: all 0.5s ease-in-out;
//                     transition: all 0.5s ease-in-out;
//                 }

//                 .dropbtn:hover .formIcon {
//                     background: $applicationBlackIcon_SVG;
//                     background-size: 16px;
//                     background-position: top left;
//                     -webkit-transition: all 0.5s ease-in-out;
//                     -moz-transition: all 0.5s ease-in-out;
//                     transition: all 0.5s ease-in-out;
//                 }

//                 .dropbtn.active {
//                     color: $color-red-darker;
//                     background: $color-offwhitelight;
//                     background-image: $bgTexure;
//                 }

//                 .dropbtn.active:hover {
//                     background: $color-offwhitelight;
//                     background-image: $bgTexure;
//                     color: $color-blackblue;
//                 }

//                 .dropbtn.active:hover .dashboardIcon {
//                     background: $dashboardBlackIcon_SVG;
//                     background-size: 16px;
//                     background-position: top left;
//                     -webkit-transition: all 0.5s ease-in-out;
//                     -moz-transition: all 0.5s ease-in-out;
//                     transition: all 0.5s ease-in-out;
//                 }

//                 .dropbtn.active:hover .documentIcon {
//                     background: $documentBlackIcon_SVG;
//                     background-size: 16px;
//                     background-position: top left;
//                     -webkit-transition: all 0.5s ease-in-out;
//                     -moz-transition: all 0.5s ease-in-out;
//                     transition: all 0.5s ease-in-out;
//                 }

//                 .dropbtn.active:hover .preapproveIcon {
//                     background: $preapproveBlackIcon_SVG;
//                     background-size: 16px;
//                     background-position: top left;
//                     -webkit-transition: all 0.5s ease-in-out;
//                     -moz-transition: all 0.5s ease-in-out;
//                     transition: all 0.5s ease-in-out;
//                 }

//                 .dropbtn.active:hover .formIcon {
//                     background: $applicationBlackIcon_SVG;
//                     background-size: 16px;
//                     background-position: top left;
//                     -webkit-transition: all 0.5s ease-in-out;
//                     -moz-transition: all 0.5s ease-in-out;
//                     transition: all 0.5s ease-in-out;
//                 }

//                 .dropdown-content {
//                     display: none;
//                     position: absolute;
//                     min-width: 160px;
//                     // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
//                     z-index: 998;

//                     a {
//                         display: block;
//                         float: none;
//                         color: $color-blackbrown;
//                         font-family: $font-agaramondB;
//                         padding: 8px 20px 8px 16px;
//                         text-decoration: none;
//                         text-align: left;
//                         background: $color-white;
//                         border-bottom: 1px solid $color-whitered;
//                     }

//                     a:hover,
//                     a:first-child:hover {
//                         color: $color-red;
//                         background-image: $bgTexure;
//                     }

//                     a:last-child {
//                         border-bottom: none;
//                     }
//                 }
//             }

//             .dropdown:hover .dropbtn.active .dashboardIcon {
//                 background: $dashboardBlackIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .dropdown:hover .dropbtn .dashboardIcon {
//                 background: $dashboardBlackIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .dropdown:hover .dropbtn.active .documentIcon {
//                 background: $documentBlackIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .dropdown:hover .dropbtn .documentIcon {
//                 background: $documentBlackIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .dropdown:hover .dropbtn.active .preapproveIcon {
//                 background: $preapproveBlackIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .dropdown:hover .dropbtn .preapproveIcon {
//                 background: $preapproveBlackIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .dropdown:hover .dropbtn.active .formIcon {
//                 background: $applicationBlackIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .dropdown:hover .dropbtn .formIcon {
//                 background: $applicationBlackIcon_SVG;
//                 background-size: 16px;
//                 background-position: top left;
//             }

//             .dropdown:hover .arrow_down_topnav {
//                 border-left: 6px solid transparent;
//                 border-right: 6px solid transparent;
//                 border-bottom: 6px solid $color-red;
//                 border-top: none;
//             }

//             .dropdown:hover .line_topnav {
//                 background: none;
//                 margin: -5px 0 0 5px;
//                 width: 0;
//                 height: 0;
//                 border-left: 6px solid transparent;
//                 border-right: 6px solid transparent;
//                 border-bottom: 6px solid $color-red;
//             }

//             .dropdown:hover .dropbtn.active {
//                 background: $color-offwhitelight;
//                 background-image: $bgTexure;
//                 color: $color-black;
//             }

//             .dropdown:hover .dropbtn {
//                 background: $color-offwhitelight;
//                 background-image: $bgTexure;
//                 color: $color-black;
//             }

//             .dropbtn.active:hover {
//                 color: $color-white;
//             }

//             .dropdown:hover .dropdown-content {
//                 display: block;
//             }
//         }

//         @media screen and (max-width: $breakpoint-1200) {
//             a {
//                 display: inline-block;
//                 vertical-align: middle;
//             }

//             .topnav a:not(:first-child),
//             .dropdown .dropbtn {
//                 display: none;
//             }

//             .topnav a.icon {
//                 float: right;
//                 display: block;
//             }

//             .topnav.responsive {
//                 position: relative;
//                 margin-left: 0px;
//                 padding-left: 0px;
//             }

//             .topnav.responsive .icon {
//                 position: absolute;
//                 height: 40px;
//                 width: 40px;
//                 right: 0;
//                 top: 0;
//                 background: $menuCrossRed_SVG;
//                 background-size: 16px;
//                 background-position: center center;
//                 border-left: 1px solid $color-lighterwhite;
//             }

//             .topnav.responsive a {
//                 display: block;
//                 float: none;
//                 text-align: left;
//             }

//             .topnav.responsive .dropdown {
//                 float: none;
//             }

//             .topnav.responsive .dropdown-content {
//                 position: relative;

//                 a {
//                     color: $color-black;
//                     margin-left: -5px;
//                 }

//                 a::before {
//                     content: "\0023f5";
//                     margin-right: 3px;
//                 }

//                 a:last-child {
//                     border-bottom: 0.5px solid $color-lighterwhite;
//                 }
//             }

//             .topnav.responsive .dropdown .dropbtn {
//                 display: block;
//                 width: 100%;
//                 text-align: left;
//                 font-size: $fontsize-16;
//                 color: $color-black;
//                 padding-left: 10px;
//             }

//             // .dropdown:hover .dropbtn {
//             //     border-top: 1px solid $color-lighterwhite;
//             // }
//         }
//     }

//     .headerTopRightResponsive {
//         float: right;
//         margin-top: 10px;

//         .header_Ph_blk_responsive {
//             display: inline-block;
//             vertical-align: top;
//             border-left: 1px solid $color-whitebluedark;
//             margin: 0px 2px 0px 10px;
//             padding: 5px 0px 0px 15px;

//             .callus {
//                 display: block;
//                 font-family: $font-agaramondB;
//                 font-size: $fontsize-13;
//                 color: $color-red;
//                 margin-bottom: 2px;
//             }

//             .phNo {
//                 display: block;
//                 font-family: $font-agaramondB;
//                 font-size: $fontsize-20;
//                 color: $color-blackblue;
//             }
//         }

//         ul.header_Account_blk_responsive {
//             display: inline-block;
//             vertical-align: top;
//             margin: 0px;
//             padding: 5px 0px 3px 0px;

//             li.header_profile_img_responsive {
//                 display: inline-block;
//                 vertical-align: top;
//                 height: 40px;
//                 aspect-ratio: 1/1;
//                 border-radius: 50%;
//                 border: 1px solid $color-red-light;
//                 background: $profileDefaultIcon_SVG;
//                 background-position: center bottom;
//                 background-size: 27px;
//                 margin-top: -5px;
//             }

//             @media screen and (max-width: $breakpoint-499) {
//                 li.header_profile_img_responsive {
//                     display: none;
//                 }
//             }

//             li.header_nameAcc_blk_responsive {
//                 display: inline-block;
//                 vertical-align: top;
//                 margin-left: 10px;

//                 .headerProfileName_mini {
//                     display: block;
//                     width: 100px;
//                     font-family: $font-agaramondB;
//                     font-size: $fontsize-13;
//                     color: $color-red;
//                     white-space: nowrap;
//                     overflow: hidden;
//                     text-overflow: ellipsis;
//                 }

//                 .headerAccount_mini {
//                     display: block;

//                     .accountnav_mini {
//                         overflow: hidden;

//                         .arrow_down_mini {
//                             display: inline-block;
//                             vertical-align: top;
//                             margin-top: 9px;
//                             margin-left: 7px;
//                             width: 0;
//                             height: 0;
//                             border-left: 7px solid transparent;
//                             border-right: 7px solid transparent;
//                             border-top: 7px solid $color-blue-darker;
//                         }

//                         .loginIcon,
//                         .profileInfoIcon,
//                         .supportIcon,
//                         .fgtpasswordIcon,
//                         .logoutNewIcon,
//                         // .consentIcon,
//                         .subscriptionIcon {
//                             display: inline-block;
//                             vertical-align: top;
//                             height: 20px;
//                             width: 20px;
//                             margin-right: 9px;
//                         }

//                         .loginIcon {
//                             background: $loginSignupIcon_SVG;
//                             background-size: 18px;
//                             background-position: center center;
//                         }

//                         .profileInfoIcon {
//                             background: $profileInfoIcon_SVG;
//                             background-size: 18px;
//                             background-position: center center;
//                         }

//                         .supportIcon {
//                             background: $supportIcon_SVG;
//                             background-size: 18px;
//                             background-position: center center;
//                         }

//                         .fgtpasswordIcon {
//                             background: $fgtpswdIcon_SVG;
//                             background-size: 16px;
//                             background-position: center center;
//                         }

//                         .logoutNewIcon {
//                             background: $logout_offwhite_Icon_SVG;
//                             background-size: 16px;
//                             background-position: center center;
//                         }

//                         // .consentIcon {
//                         //     background: $consent_Icon_SVG;
//                         //     background-size: 16px;
//                         //     background-position: center center;
//                         // }

//                         // .subscriptionIcon {
//                         //     background: $subscription_Icon_SVG;
//                         //     background-size: 16px;
//                         //     background-position: center center;
//                         // }

//                         .dropdownAcc_mini {
//                             float: left;
//                             overflow: hidden;

//                             .dropbtnAcc_mini {
//                                 border: none;
//                                 outline: none;
//                                 color: $color-blue-darker;
//                                 font-family: $font-agaramondB;
//                                 font-size: $fontsize-21;
//                                 margin: 0px;
//                                 background: transparent;
//                             }

//                             .dropbtnAcc_mini:hover {
//                                 cursor: pointer;
//                             }

//                             .dropbtnAcc_mini:hover .arrow_down_mini {
//                                 border-left: 7px solid transparent;
//                                 border-right: 7px solid transparent;
//                                 border-bottom: 7px solid $color-red;
//                                 border-top: none;
//                             }

//                             .dropdown-content_mini {
//                                 display: none;
//                                 position: absolute;
//                                 min-width: 200px;
//                                 z-index: 999;

//                                 a {
//                                     display: block;
//                                     color: $color-blue-darker;
//                                     font-family: $font-agaramondB;
//                                     padding: 10px 30px 10px 20px;
//                                     text-decoration: none;
//                                     text-align: left;
//                                     background: $color-white;
//                                     border-left: 1px solid $color-lighterwhite;
//                                     border-right: 1px solid $color-lighterwhite;
//                                 }

//                                 a:hover {
//                                     color: $color-red;
//                                     background-image: $bgTexure;
//                                 }

//                                 a:first-child {
//                                     border-top-left-radius: 7px;
//                                     border-top-right-radius: 7px;
//                                     border-top: 1px solid $color-lighterwhite;
//                                     padding-top: 22px;
//                                 }

//                                 a:last-child {
//                                     border-bottom-left-radius: 10px;
//                                     border-bottom-right-radius: 10px;
//                                     border-bottom: 1px solid $color-lighterwhite;
//                                     padding-bottom: 22px;
//                                 }
//                             }

//                             .dropdown-content_mini a:hover .loginIcon {
//                                 background: $loginSignup_mo_Icon_SVG;
//                                 background-size: 18px;
//                                 background-position: center center;
//                             }

//                             .dropdown-content_mini a:hover .profileInfoIcon {
//                                 background: $profileInfo_mo_Icon_SVG;
//                                 background-size: 18px;
//                                 background-position: center center;
//                             }

//                             .dropdown-content_mini a:hover .supportIcon {
//                                 background: $support_mo_Icon_SVG;
//                                 background-size: 18px;
//                                 background-position: center center;
//                             }

//                             .dropdown-content_mini a:hover .fgtpasswordIcon {
//                                 background: $fgtpswd_mo_Icon_SVG;
//                                 background-size: 16px;
//                                 background-position: center center;
//                             }

//                             .dropdown-content_mini a:hover .logoutNewIcon {
//                                 background: $logout_offwhite_mo_Icon_SVG;
//                                 background-size: 16px;
//                                 background-position: center center;
//                             }

//                             // .dropdown-content_mini a:hover .consentIcon {
//                             //     background: $consent_mo_Icon_SVG;
//                             //     background-size: 16px;
//                             //     background-position: center center;
//                             // }

//                             .dropdown-content_mini a:hover .subscriptionIcon {
//                                 background: $subscription_mo_Icon_SVG;
//                                 background-size: 16px;
//                                 background-position: center center;
//                             }
//                         }

//                         @media ($smaller-than: $breakpoint-1200) {
//                             .dropdownAcc_mini {
//                                 margin-right: 0px;
//                             }

//                             .dropdown-content_mini {
//                                 right: -30px;
//                             }
//                         }

//                         .dropdownAcc_mini:hover .arrow_down_mini {
//                             border-left: 7px solid transparent;
//                             border-right: 7px solid transparent;
//                             border-bottom: 7px solid $color-red;
//                             border-top: none;
//                         }

//                         .dropbtnAcc_mini.active:hover {
//                             color: $color-white;
//                         }

//                         .dropdownAcc_mini:hover .dropdown-content_mini {
//                             display: block;
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     @media ($smaller-than: $breakpoint-1200) {
//         .headerTopRightResponsive {
//             position: absolute;
//             right: 50px;
//             top: 0px;
//             z-index: 120;
//         }
//     }
// }

// .headerInpage {
//     display: block;
//     position: fixed;

//     .navBlockInpage {
//         padding: 7px 0px;
//         box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);

//         .logoPosInline {
//             float: left;
//             height: 42px;
//             width: 75px;
//             margin-right: 15px;
//         }
//     }
// }

// @media ($smaller-than: $breakpoint-1200) {
//     .header {
//         display: none;
//     }

//     .headerInpage {
//         display: none;
//     }

//     .headerResponsive {
//         display: block;
//     }
// }