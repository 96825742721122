@import "support";

.siddhiLogo {
	background: $siddhiLogo_SVG;
}

.siddhiWhiteLogo {
	background: $siddhiLogo_White_SVG;
}

/*General Responsiveness*/
@media ($bigger-than: $breakpoint-1200) {
	.widthfix {
		width: $width-1200px;
		margin: 0px auto;
	}

	.widthfix_10px {
		width: $width-1200px;
		margin: 0px auto;
	}
}

@media ($smaller-than: $breakpoint-1200) {
	.widthfix {
		width: auto;
		margin: 0px 0px;
	}

	.widthfix_10px {
		width: auto;
		margin: 0px 10px;
	}
}

/*No Content*/
.noContent {
	display: block;
	padding: 20px;
	font: $fontsize-15 $font-agaramond;
	// color: $color-lightblack;
	text-align: center;
}

/*Refresh Icon*/
.blockRefresh {
	float: right;
	height: 25px;
	aspect-ratio: 1/1;
	border-radius: 3px;
	background: $refreshIcon_SVG $color-black;
	background-size: 12px;
	background-position: center center;
	margin: 0px 0 0 5px;
}

.blockRefresh:hover {
	cursor: pointer;
	background-color: $color-black;
}

/*Disable Background*/
.disable_bg,
.pop_disable_bg {
	background: $color-black;
	opacity: .3;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 2450;
}

.pop_disable_bg {
	display: none;
	background: transparent;
	opacity: 1;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABtJREFUeNpiZGBgOMNABGBiIBKMKqSOQoAAAwBVNQDgpAAyvAAAAABJRU5ErkJggg==');
	z-index: 2510;
}

.display-block {
	display: block;
}

.display-none {
	display: none;
}

.errormessage,
.successmessage {
	display: block;
	border-radius: 5px;
	padding-left: 10px;
	font: $fontsize-20 $font-times;
	color: $color-white;
	background: $color-error;
	height: 45px;
	line-height: 45px;
	margin-bottom: 10px;
}

.successmessage {
	background: $color-black;
}

@media (max-width: 699px) {

	.errormessage,
	.successmessage {
		height: 35px;
		line-height: 35px;
		font-size: $fontsize-16;
	}
}






/*Pop Structure Common Style*/
// .commonPop {
// 	min-height: 100px;
// 	position: absolute;
// 	background: $color-white;
// 	z-index: 2500;
// 	top: 30%;
// 	left: 50%;
// 	transform: translate(-50%, -50%);
// }

// .commonPop {
// 	width: 300px;
// }

.wip_blk {
	position: fixed;
	top: 50%;
	width: 300px;
	z-index: 15;
	background: $color-white;
	padding: 40px 50px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, .35);
	border-radius: 10px;
	left: 50%;
	transform: translate(-50%, -50%);

	.wipTxt {
		display: block;
		font-family: $font-agaramondB;
		font-size: $fontsize-26;
		color: $color-black;
		margin-bottom: 20px;
		text-align: center;
	}

	.close_button {
		display: block;
		margin: 0px auto;
		padding: 10px 25px;
		font-family: $font-agaramondB;
		font-size: $fontsize-18;
		color: $color-white;
		text-transform: capitalize;
		border: 0px;
		background: $color-black;
		outline: none;
		border-radius: 5px;
		transition-property: 'background';
		transition-duration: 0.5s;
		-webkit-transition: 0.5s;
		transition: 0.5s;
	}

	.close_button:hover {
		cursor: pointer;
		background: $color-red-light;
	}
}